import React, { useState, useRef } from 'react';
import { Printer, Download, ChevronDown, ChevronUp, FileText } from 'lucide-react';
import { QRCodeCanvas } from 'qrcode.react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { AssetLabelPDFDocument } from './pdf/AssetLabelPDFDocument';

const PrintLabel = ({ asset }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedFields, setSelectedFields] = useState({
    name: true,
    serialNumber: true,
    support: true
  });
  const qrRef = useRef();

  // EZPL (ZPL) Generierung
  const generateEZPL = () => {
    const commands = [
      '^XA',  // Start Label
      '^CI28', // UTF-8 Encoding
      '^PW831', // Druckbreite (831 dots = ~104mm)
      '^LL370', // Labellänge (370 dots = ~46mm)
      '^LH0,0', // Label Home Position
      
      // Firmenname
      '^FO20,20^A0N,25,25^FDArmann Systems^FS',
      
      // Logo Position (durch Text ersetzt)
      '^FO20,50^GB100,3,3^FS', // Trennlinie
      
      // Gerätename (wenn ausgewählt)
      selectedFields.name ? [
        '^FO20,70^A0N,20,20^FDGerätename:^FS',
        `^FO20,95^A0N,35,35^FD${asset?.Name || 'N/A'}^FS`
      ].join('\n') : '',
      
      // Seriennummer (wenn ausgewählt)
      selectedFields.serialNumber ? [
        '^FO20,140^A0N,20,20^FDSeriennummer:^FS',
        `^FO20,165^A0N,28,28^FD${asset?.system?.serialNumber || 'N/A'}^FS`
      ].join('\n') : '',
      
      // Support Info (wenn ausgewählt)
      selectedFields.support ? [
        '^FO20,220^A0N,20,20^FDSupport:^FS',
        '^FO20,245^A0N,20,20^FD+49 9401 91791 500^FS',
        '^FO20,270^A0N,20,20^FDsupport@armann-systems.com^FS',
        '^FO20,295^A0N,20,20^FDarmann-systems.com^FS'
      ].join('\n') : '',

      // QR Code (rechtsbündig)
      `^FO500,20^BQN,2,4^FDQA,https://support.armann-systems.com/assets/${asset?.id || ''}^FS`,
      
      // Rahmen um QR Code
      '^FO495,15^GB240,240,2^FS',
      
      '^XZ'  // Ende Label
    ].join('\n');

    return commands;
  };

  // Download Handler
  const downloadEZPL = () => {
    const content = generateEZPL();
    const blob = new Blob([content], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `etikett-${asset?.system?.serialNumber || 'asset'}.zpl`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  // Browser Print Handler
  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    const qrCanvas = qrRef.current?.querySelector('canvas');
    const qrDataUrl = qrCanvas?.toDataURL() || '';
    
    const content = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Asset Etikett</title>
          <style>
            @page {
              size: 62mm 29mm;
              margin: 0;
            }
            body {
              margin: 0;
              padding: 3mm;
              width: 56mm;
              height: 23mm;
              font-family: system-ui, -apple-system, sans-serif;
              display: flex;
            }
            .content {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              max-width: 60%;
            }
            .header {
              margin-bottom: 2mm;
            }
            .company-name {
              font-size: 8pt;
              font-weight: 600;
              text-transform: uppercase;
              color: #000;
              border-bottom: 0.5pt solid #000;
              padding-bottom: 1mm;
              margin-bottom: 2mm;
            }
            .device-info {
              margin: 2mm 0;
            }
            .label {
              font-size: 6pt;
              color: #666;
              margin-bottom: 0.5mm;
            }
            .device-name {
              font-size: 10pt;
              font-weight: 600;
              margin-bottom: 2mm;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .serial-number {
              font-size: 9pt;
              font-family: monospace;
              color: #444;
            }
            .support-info {
              font-size: 6pt;
              color: #666;
              line-height: 1.3;
            }
            .qr-section {
              position: absolute;
              right: 3mm;
              top: 3mm;
              width: 23mm;
              height: 23mm;
              border: 0.3mm solid #000;
              padding: 1mm;
            }
            .qr-code {
              width: 100%;
              height: 100%;
            }
            .qr-code img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          </style>
        </head>
        <body>
          <div class="content">
            <div>
              <div class="header">
                <div class="company-name">Armann Systems</div>
              </div>
              
              ${selectedFields.name || selectedFields.serialNumber ? `
                <div class="device-info">
                  ${selectedFields.name ? `
                    <div class="label">Gerätename:</div>
                    <div class="device-name">${asset?.Name || 'N/A'}</div>
                  ` : ''}
                  ${selectedFields.serialNumber ? `
                    <div class="label">Seriennummer:</div>
                    <div class="serial-number">${asset?.system?.serialNumber || 'N/A'}</div>
                  ` : ''}
                </div>
              ` : ''}
            </div>
            
            ${selectedFields.support ? `
              <div class="support-info">
                <div class="label">Support:</div>
                <div>+49 9401 91791 500</div>
                <div>support@armann-systems.com</div>
                <div>armann-systems.com</div>
              </div>
            ` : ''}
          </div>
          
          <div class="qr-section">
            <div class="qr-code">
              <img src="${qrDataUrl}" alt="QR Code" />
            </div>
          </div>
        </body>
      </html>
    `;

    printWindow.document.write(content);
    printWindow.document.close();
    
    printWindow.onload = () => {
      printWindow.print();
      printWindow.close();
    };
  };

  return (
    <div className="">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center text-gray-600 hover:text-gray-900 text-sm font-medium"
      >
        <Printer className="w-4 h-4 mr-2" />
        Etikett drucken
        {isExpanded ? <ChevronUp className="w-4 h-4 ml-2" /> : <ChevronDown className="w-4 h-4 ml-2" />}
      </button>

      {isExpanded && (
        <div className="mt-4 space-y-4">
          <div className="flex flex-wrap gap-3">
            <button
              onClick={handlePrint}
              className="inline-flex items-center px-3 py-1.5 bg-blue-600 text-white text-sm rounded hover:bg-blue-700 transition-colors"
            >
              <Printer className="w-4 h-4 mr-1.5" />
              Browser-Druck
            </button>
            <button
              onClick={downloadEZPL}
              className="inline-flex items-center px-3 py-1.5 bg-gray-100 text-gray-700 text-sm rounded hover:bg-gray-200 transition-colors"
            >
              <Download className="w-4 h-4 mr-1.5" />
              EZPL
            </button>
            <PDFDownloadLink
              document={<AssetLabelPDFDocument asset={asset} selectedFields={selectedFields} />}
              fileName={`etikett-${asset?.system?.serialNumber || 'asset'}.pdf`}
              className="inline-flex items-center px-3 py-1.5 bg-gray-100 text-gray-700 text-sm rounded hover:bg-gray-200 transition-colors"
            >
              <FileText className="w-4 h-4 mr-1.5" />
              PDF
            </PDFDownloadLink>
          </div>

          <div className="flex flex-wrap gap-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={selectedFields.name}
                onChange={() => setSelectedFields(prev => ({ ...prev, name: !prev.name }))}
                className="rounded border-gray-300 text-blue-600 mr-2"
              />
              <span className="text-sm text-gray-600">Gerätename</span>
            </label>

            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={selectedFields.serialNumber}
                onChange={() => setSelectedFields(prev => ({ ...prev, serialNumber: !prev.serialNumber }))}
                className="rounded border-gray-300 text-blue-600 mr-2"
              />
              <span className="text-sm text-gray-600">Seriennummer</span>
            </label>

            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={selectedFields.support}
                onChange={() => setSelectedFields(prev => ({ ...prev, support: !prev.support }))}
                className="rounded border-gray-300 text-blue-600 mr-2"
              />
              <span className="text-sm text-gray-600">Support-Info</span>
            </label>
          </div>

          <div className="text-xs text-gray-500">
            Format: 62mm x 29mm (300 DPI) • QR-Code enthält Asset-ID
          </div>
        </div>
      )}

      <div className="hidden" ref={qrRef}>
        <QRCodeCanvas 
          value={`https://support.armann-systems.com/assets/${asset?.id || ''}`}
          size={256}
          level="H"
        />
      </div>
    </div>
  );
};

export default PrintLabel;
