import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useAuth } from '../contexts/AuthContext';
import { apiClient } from '../utils/apiClient';
import { toast } from 'react-toastify';
import { LoaderContainer } from '../components/Loader';
import { ArrowLeft, AlertCircle, Calendar } from 'lucide-react';

const formatDate = (dateString) => {
  try {
    return new Date(dateString).toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  } catch (e) {
    return 'Ungültiges Datum';
  }
};

export const WikiDetailPage = () => {
  const [wikiPage, setWikiPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const loadWikiPage = async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(`/wiki/page/${id}`);
        setWikiPage(response.wiki_page);
        setError(null);
      } catch (err) {
        setError(err.message);
        toast.error('Fehler beim Laden der Wiki-Seite');
      } finally {
        setLoading(false);
      }
    };

    loadWikiPage();
  }, [id]);

  if (loading) {
    return <LoaderContainer text="Wiki-Seite wird geladen..." />;
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 rounded-xl p-4 text-red-700">
          <div className="flex items-center gap-2 mb-2">
            <AlertCircle size={20} />
            <span className="font-medium">Fehler beim Laden der Wiki-Seite</span>
          </div>
          <p className="mb-4">{error}</p>
          <button
            onClick={() => navigate('/wiki')}
            className="px-4 py-2 bg-red-100 rounded-md hover:bg-red-200 transition-colors"
          >
            Zurück zur Übersicht
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>{wikiPage?.name} - Armann Systems GmbH</title>
      </Helmet>
      <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {/* Navigation */}
        <button
          onClick={() => navigate('/wiki')}
          className="mb-6 flex items-center text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft size={20} className="mr-2" />
          Zurück zur Übersicht
        </button>

        {/* Wiki Page Content */}
        <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
          {/* Header */}
          <div className="border-b border-gray-200 bg-gray-50 px-6 py-4">
            <h1 className="text-2xl font-bold text-gray-900">{wikiPage?.name}</h1>
            <div className="mt-2 flex items-center gap-4 text-sm text-gray-500">
              <div className="flex items-center">
                <Calendar size={16} className="mr-1" />
                Erstellt: {formatDate(wikiPage?.created_at)}
              </div>
              <div className="flex items-center">
                <Calendar size={16} className="mr-1" />
                Aktualisiert: {formatDate(wikiPage?.updated_at)}
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="px-6 py-4">
            <div 
              className="prose max-w-none"
              dangerouslySetInnerHTML={{ __html: wikiPage?.interpolated_body || wikiPage?.body }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
