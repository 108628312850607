import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Terminal, Power, AlertCircle } from 'lucide-react';
import './RemotePowershell.css';

const COMMON_COMMANDS = [
  'Get-Process', 'Get-Service', 'Get-ChildItem', 'Set-Location', 'Get-Content',
  'Stop-Process', 'Start-Service', 'Stop-Service', 'New-Item', 'Remove-Item',
  'Get-WmiObject', 'Invoke-Command', 'Get-EventLog', 'Clear-Host', 'Get-Help'
];

const RemotePowershell = ({ assetId }) => {
  const [sessionActive, setSessionActive] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [commandHistory, setCommandHistory] = useState([]);
  const [currentCommand, setCurrentCommand] = useState('');
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [commandBuffer, setCommandBuffer] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(-1);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [sessionToken, setSessionToken] = useState(null);
  const [isCleanupEnabled, setIsCleanupEnabled] = useState(false);

  const wsRef = useRef(null);
  const inputRef = useRef(null);
  const requestIdCounter = useRef(0);
  const executedCommands = useRef([]);
  const reconnectTimeoutRef = useRef(null);
  const terminalOutputRef = useRef(null);

  const getNextRequestId = () => {
    requestIdCounter.current += 1;
    return `req_${requestIdCounter.current}`;
  };

  const updateSuggestions = useCallback((input) => {
    if (!input) {
      setSuggestions([]);
      return;
    }
    
    const matches = COMMON_COMMANDS.filter(cmd => 
      cmd.toLowerCase().startsWith(input.toLowerCase())
    );
    setSuggestions(matches);
    setSelectedSuggestion(-1);
  }, []);

  const terminateSession = useCallback(async () => {
    if (!isCleanupEnabled) return; // Skip if cleanup not enabled
    
    if (sessionToken) {
      try {
        await fetch(`/api/powershell/sessions/${sessionToken}`, {
          method: 'DELETE',
          headers: {
            'Keep-Alive': 'timeout=5, max=1000'
          }
        });
      } catch (error) {
        console.error('Sitzungsbeendigung fehlgeschlagen:', error);
      }
    }
    
    if (wsRef.current) {
      wsRef.current.close();
    }
    
    setSessionActive(false);
    setSessionToken(null);
    setError(null);
    setReconnectAttempts(0);
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
    }
  }, [sessionToken, isCleanupEnabled]);

  const connectWebSocket = useCallback(async (token) => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const ws = new WebSocket(`${protocol}//${window.location.host}/powershell?token=${token}`);
    
    ws.onopen = () => {
      setConnecting(false);
      setSessionActive(true);
      setError(null);
      setReconnectAttempts(0);
      setCommandHistory([
        { type: 'system', content: 'Windows PowerShell' },
        { type: 'system', content: 'Copyright (C) Microsoft Corporation. Alle Rechte vorbehalten.' },
        { type: 'prompt', content: 'PS C:\\>' }
      ]);
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      
      if (data.type === 'result') {
        setCommandHistory(prev => [...prev,
          { type: 'output', content: data.output },
          { type: 'prompt', content: 'PS C:\\>' }
        ]);
      } else if (data.type === 'error') {
        setCommandHistory(prev => [...prev,
          { type: 'error', content: data.message },
          { type: 'prompt', content: 'PS C:\\>' }
        ]);
      } else if (data.type === 'system') {
        setCommandHistory(prev => [...prev,
          { type: 'system', content: data.message },
          { type: 'prompt', content: 'PS C:\\>' }
        ]);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket-Fehler:', error);
      setError('Verbindungsfehler aufgetreten');
    };

    ws.onclose = () => {
      setSessionActive(false);
      
      if (reconnectAttempts < 3) {
        setError(`Verbindung verloren. Verbindungsversuch... (${reconnectAttempts + 1}/3)`);
        reconnectTimeoutRef.current = setTimeout(() => {
          setReconnectAttempts(prev => prev + 1);
          startSession();
        }, 3000);
      } else {
        setError('Verbindung verloren. Maximale Anzahl an Wiederverbindungsversuchen erreicht.');
        setCommandHistory([]);
        setSessionToken(null);
      }
    };

    wsRef.current = ws;
  }, [reconnectAttempts]);

  const startSession = async () => {
    try {
      setConnecting(true);
      setError(null);
      setIsCleanupEnabled(false); // Disable cleanup during connection
      
      const response = await fetch('/api/powershell/sessions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Keep-Alive': 'timeout=5, max=1000'
        },
        body: JSON.stringify({ assetId })
      });
      
      if (!response.ok) {
        throw new Error('Sitzungserstellung fehlgeschlagen');
      }

      const { sessionToken: token } = await response.json();
      setSessionToken(token);
      await connectWebSocket(token);
      setIsCleanupEnabled(true); // Enable cleanup only after successful connection
    } catch (error) {
      setConnecting(false);
      setError('PowerShell-Sitzung konnte nicht gestartet werden');
      console.error('Sitzungsstart-Fehler:', error);
    }
  };

  // Modified cleanup effect
  useEffect(() => {
    return () => {
      if (isCleanupEnabled) {
        terminateSession();
      }
    };
  }, [terminateSession, isCleanupEnabled]);

  const handleCommandSubmit = async (e) => {
    e.preventDefault();
    if (!currentCommand.trim() || !wsRef.current) return;

    const command = currentCommand.trim();
    setCurrentCommand('');
    setSuggestions([]);
    
    executedCommands.current = [...executedCommands.current, command];
    setHistoryIndex(-1);
    
    setCommandHistory(prev => [...prev,
      { type: 'command', content: command }
    ]);

    const requestId = getNextRequestId();
    wsRef.current.send(JSON.stringify({
      type: 'execute',
      requestId,
      command
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (historyIndex < executedCommands.current.length - 1) {
        const newIndex = historyIndex + 1;
        setHistoryIndex(newIndex);
        setCurrentCommand(executedCommands.current[executedCommands.current.length - 1 - newIndex]);
        setSuggestions([]);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (historyIndex > 0) {
        const newIndex = historyIndex - 1;
        setHistoryIndex(newIndex);
        setCurrentCommand(executedCommands.current[executedCommands.current.length - 1 - newIndex]);
      } else if (historyIndex === 0) {
        setHistoryIndex(-1);
        setCurrentCommand(commandBuffer);
      }
    }
    else if (e.key === 'Tab') {
      e.preventDefault();
      if (suggestions.length > 0) {
        const newIndex = (selectedSuggestion + 1) % suggestions.length;
        setSelectedSuggestion(newIndex);
        setCurrentCommand(suggestions[newIndex]);
      }
    }
    else if (e.ctrlKey && e.key === 'f') {
      e.preventDefault();
      setSearchActive(true);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setCurrentCommand(value);
    setCommandBuffer(value);
    updateSuggestions(value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (terminalOutputRef.current) {
      terminalOutputRef.current.scrollTop = terminalOutputRef.current.scrollHeight;
    }
  }, [commandHistory]);

  useEffect(() => {
    return () => {
      terminateSession();
    };
  }, [terminateSession]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      terminateSession();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [terminateSession]);

  if (!sessionActive) {
    return (
      <div className="flex flex-col items-center justify-center h-96 bg-white rounded-lg border-2 border-gray-900 p-6">
        {error && (
          <div className="mb-4 p-3 bg-gray-50 border-2 border-gray-900 rounded-lg flex items-center text-gray-900">
            <AlertCircle className="w-5 h-5 mr-2" />
            <span>{error}</span>
          </div>
        )}
        
        {connecting ? (
          <div className="text-center">
            <div className="terminal-loader mb-4"></div>
            <h3 className="text-lg font-bold text-gray-900 mb-2">Verbindung wird hergestellt...</h3>
            <p className="text-gray-900">Sichere PowerShell-Sitzung wird initialisiert</p>
          </div>
        ) : (
          <>
            <Terminal className="w-16 h-16 text-gray-900 mb-4" />
            <h3 className="text-lg font-bold text-gray-900 mb-2">Remote PowerShell</h3>
            <p className="text-gray-900 mb-4 text-center">
              Starten Sie eine sichere PowerShell-Sitzung für die Fernsteuerung
            </p>
            <button
              onClick={startSession}
              className="inline-flex items-center px-6 py-2.5 border-2 border-gray-900 rounded-lg text-sm font-bold text-gray-900 bg-white hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-900"
            >
              <Power className="mr-2 h-4 w-4" />
              Sitzung starten
            </button>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="terminal-container rounded-lg border-2 border-gray-900 h-96 flex flex-col">
      <div className="flex items-center justify-between px-4 py-2 bg-gray-100 border-b-2 border-gray-900 rounded-t-lg">
        <div className="flex items-center">
          <Terminal className="w-4 h-4 mr-2 text-gray-900" />
          <span className="text-sm font-bold text-gray-900">Remote PowerShell</span>
        </div>
        <button
          onClick={terminateSession}
          className="text-gray-900 hover:text-gray-700 focus:outline-none transition-colors duration-200"
        >
          <Power className="w-4 h-4" />
        </button>
      </div>

      <div ref={terminalOutputRef} className="terminal-output flex-1 p-4 overflow-y-auto bg-white">
        {commandHistory.map((entry, index) => (
          <div key={index} className="terminal-line whitespace-pre-wrap">
            {entry.type === 'system' && (
              <div className="text-gray-900 font-mono mb-1">{entry.content}</div>
            )}
            {entry.type === 'prompt' && (
              <div className="text-gray-900 font-mono font-bold">{entry.content}</div>
            )}
            {entry.type === 'command' && (
              <div className="text-gray-900 font-mono pl-4">{entry.content}</div>
            )}
            {entry.type === 'output' && (
              <div className="text-gray-900 font-mono pl-4 mb-2">{entry.content}</div>
            )}
            {entry.type === 'error' && (
              <div className="text-gray-900 font-mono pl-4 mb-2 border-l-2 border-gray-900">{entry.content}</div>
            )}
          </div>
        ))}
      </div>

      <form onSubmit={handleCommandSubmit} className="terminal-input p-4 border-t-2 border-gray-900 bg-gray-100">
        <div className="flex flex-col">
          <div className="flex items-center">
            <span className="text-gray-900 font-mono font-bold mr-2">PS C:\{'>'}</span>
            <input
              ref={inputRef}
              type="text"
              value={currentCommand}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              className="flex-1 bg-transparent border-none text-gray-900 placeholder-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-900 font-mono"
              placeholder="Befehl eingeben... (↑↓ für Verlauf, Tab für Vorschläge)"
            />
          </div>
          {suggestions.length > 0 && (
            <div className="mt-2 p-2 bg-white border-2 border-gray-900 rounded-md">
              {suggestions.map((suggestion, index) => (
                <div
                  key={suggestion}
                  className={`px-2 py-1 cursor-pointer rounded font-mono ${
                    index === selectedSuggestion ? 'bg-gray-100' : ''
                  }`}
                  onClick={() => {
                    setCurrentCommand(suggestion);
                    setSuggestions([]);
                    inputRef.current?.focus();
                  }}
                >
                  {suggestion}
                </div>
              ))}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default RemotePowershell;