import React from 'react';

export const ChartLoader = () => (
  <div className="bg-white p-6 rounded-lg border shadow-sm">
    <div className="h-[300px] flex items-center justify-center">
      <div className="text-gray-500">Lade Diagramm...</div>
    </div>
  </div>
);

export default ChartLoader;
