import React from 'react';
import { HardDrive, Cpu, Network } from 'lucide-react';

export const HardwareSection = ({ hardware, model }) => {
  if (!hardware) return null;

  return (
    <div className="p-4 border-b border-gray-200">
      <h3 className="font-medium text-gray-900 mb-3 flex items-center gap-2">
        <HardDrive className="h-4 w-4 text-gray-500" />
        Hardware Details
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* System Info */}
        <div className="space-y-2">
          <div className="flex justify-between text-sm">
            <span className="text-gray-500">Model:</span>
            <span className="text-gray-900">{model || 'N/A'}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-500">Serial:</span>
            <span className="text-gray-900">{hardware.system?.serialNumber || 'N/A'}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-500">Type:</span>
            <span className="text-gray-900">{hardware.system?.type || 'N/A'}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-500">BIOS:</span>
            <span className="text-gray-900">{hardware.bios || 'N/A'}</span>
          </div>
        </div>

        {/* CPU & Memory */}
        <div className="space-y-2">
          <div className="flex items-start gap-2">
            <Cpu className="h-4 w-4 text-gray-500 mt-1 flex-shrink-0" />
            <div className="flex-1">
              <div className="text-sm text-gray-500">CPU</div>
              <div className="text-sm text-gray-900">{hardware.cpu?.type?.trim() || 'N/A'}</div>
            </div>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-500">CPU Speed:</span>
            <span className="text-gray-900">{hardware.cpu?.speed?.trim() || 'N/A'}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-500">CPU Alerts (24h):</span>
            <span className={`font-medium ${hardware.cpu?.alerts24h > 0 ? 'text-red-600' : 'text-green-600'}`}>
              {hardware.cpu?.alerts24h || 0}
            </span>
          </div>
        </div>

        {/* Network */}
        <div className="space-y-2">
          <div className="flex items-start gap-2">
            <Network className="h-4 w-4 text-gray-500 mt-1 flex-shrink-0" />
            <div className="flex-1">
              <div className="text-sm text-gray-500">Network</div>
              <div className="text-sm text-gray-900 break-all">
                IP: {hardware.network?.ipAddress || 'N/A'}
              </div>
              <div className="text-sm text-gray-900 break-all">
                MAC: {hardware.network?.macAddress || 'N/A'}
              </div>
            </div>
          </div>
          {hardware.network?.adapter && (
            <div className="text-sm">
              <div className="text-gray-500">Network Adapters:</div>
              <div className="text-gray-900 text-xs mt-1 space-y-1">
                {hardware.network.adapter.split('\n').filter(Boolean).map((adapter, index) => (
                  <div key={index} className="bg-gray-50 p-1 rounded">
                    {adapter.trim()}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HardwareSection;
