import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { apiClient } from '../utils/apiClient';
import { Loader, LoaderContainer } from '../components/Loader';
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/AuthContext';
import { 
  Clock, 
  User, 
  MessageSquare, 
  Send, 
  ArrowLeft,
  X,
  Maximize2
} from 'lucide-react';

const STATUS_BADGES = {
  'Unassigned': 'bg-yellow-100 text-yellow-800 border-yellow-200',
  'Assigned': 'bg-blue-100 text-blue-800 border-blue-200',
  'Closed': 'bg-gray-100 text-gray-800 border-gray-200',
  'Auto-Closed': 'bg-gray-100 text-gray-800 border-gray-200',
  'Archived': 'bg-purple-100 text-purple-800 border-purple-200'
};

const STATUS_TRANSLATIONS = {
  'Unassigned': 'Nicht zugewiesen',
  'Assigned': 'Zugewiesen',
  'Closed': 'Geschlossen',
  'Auto-Closed': 'Auto-Geschlossen',
  'Archived': 'Archiviert'
};

const ImageModal = ({ isOpen, onClose, imageUrl, alt }) => {
  const [loaded, setLoaded] = useState(false);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-75" onClick={onClose}>
      <div className="relative max-w-4xl max-h-[90vh] w-full bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="absolute top-4 right-4 z-10">
          <button
            onClick={onClose}
            className="p-2 rounded-full bg-black bg-opacity-50 hover:bg-opacity-75 text-white transition-colors"
          >
            <X size={20} />
          </button>
        </div>
        
        <div className="relative w-full h-full flex items-center justify-center p-4">
          {!loaded && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
            </div>
          )}
          <img
            src={imageUrl}
            alt={alt}
            className={`max-w-full max-h-[80vh] object-contain ${loaded ? 'block' : 'invisible'}`}
            onLoad={() => setLoaded(true)}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </div>
    </div>
  );
};

export const TicketDetailPage = () => {
  const { ticketId } = useParams();
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const loadTicket = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/customers/${user.naveriskClientId}/tickets/${ticketId}`);
      setTicket(response);
      setError(null);
    } catch (err) {
      setError(err.message);
      toast.error('Fehler beim Laden der Ticketdetails');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.naveriskClientId) {
      loadTicket();
    }
  }, [ticketId, user?.naveriskClientId]);

  if (loading) {
    return <LoaderContainer text="Ticket wird geladen..." />;
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 rounded-xl p-4 text-red-700">
          Fehler beim Laden des Tickets: {error}
          <button
            onClick={loadTicket}
            className="ml-4 px-3 py-1 bg-red-100 rounded-md hover:bg-red-200 transition-colors"
          >
            Erneut versuchen
          </button>
        </div>
      </div>
    );
  }

  if (!ticket) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-yellow-50 border border-yellow-200 rounded-xl p-4 text-yellow-700">
          Ticket nicht gefunden
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Ticket Details - Armann Systems GmbH</title>
      </Helmet>
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <Link 
            to="/tickets" 
            className="inline-flex items-center text-sm text-gray-600 hover:text-gray-900 mb-4"
          >
            <ArrowLeft size={16} className="mr-1" />
            Zurück zur Übersicht
          </Link>
          <div className="flex items-start justify-between">
            <h1 className="text-2xl font-bold text-gray-900">
              {ticket.TicketIdentifier}
            </h1>
            <div className="flex gap-2">
              <span className={`px-3 py-1 text-sm font-medium rounded-full border ${
                STATUS_BADGES[ticket.TicketStatus] || STATUS_BADGES.default
              }`}>
                {STATUS_TRANSLATIONS[ticket.TicketStatus] || ticket.TicketStatus}
              </span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Main Content */}
          <div className="lg:col-span-2 space-y-6">
            {/* Ticket Details */}
            <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
              <h2 className="text-xl font-bold text-gray-900 mb-4">{ticket.Trigger}</h2>
              <div className="space-y-4">
                <div className="flex items-center text-sm text-gray-700">
                  <Clock size={16} className="mr-2" />
                  <span>Erstellt am: {new Date(ticket.CreatedDate).toLocaleString('de-DE')}</span>
                </div>
                {ticket.Description && (
                  <div className="mt-4 text-gray-700 prose max-w-none" dangerouslySetInnerHTML={{ __html: ticket.Description }} />
                )}
              </div>
            </div>
          </div>

          {/* Sidebar */}
          <div className="lg:col-span-1">
            <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100 space-y-6">
              {/* Ticket Details */}
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Ticket Details</h3>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm font-medium text-gray-700">Status</label>
                    <div className="mt-1 text-gray-900">{STATUS_TRANSLATIONS[ticket.TicketStatus] || ticket.TicketStatus}</div>
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-700">Quelle</label>
                    <div className="mt-1 text-gray-900">{ticket.TicketSourceName}</div>
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-700">Typ</label>
                    <div className="mt-1 text-gray-900">{ticket.TicketType}</div>
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-700">SLA Klasse</label>
                    <div className="mt-1 text-gray-900">{ticket.SLAClass}</div>
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-700">SLA Priorität</label>
                    <div className="mt-1 text-gray-900">{ticket.SLASeverity}</div>
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-700">Erstellt am</label>
                    <div className="mt-1 text-gray-900">
                      {new Date(ticket.CreatedDate).toLocaleString('de-DE')}
                    </div>
                  </div>
                  {ticket.ClosedDate && (
                    <div>
                      <label className="text-sm font-medium text-gray-700">Geschlossen am</label>
                      <div className="mt-1 text-gray-900">
                        {new Date(ticket.ClosedDate).toLocaleString('de-DE')}
                      </div>
                    </div>
                  )}
                  <div>
                    <label className="text-sm font-medium text-gray-700">Ticket ID</label>
                    <div className="mt-1 text-gray-900">#{ticket.TicketId}</div>
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-700">Kunde</label>
                    <div className="mt-1 text-gray-900">{ticket.ClientName}</div>
                  </div>
                  {ticket.AssignedUserName && ticket.AssignedUserName !== '__SYSTEM_USER__' && (
                    <div>
                      <label className="text-sm font-medium text-gray-700">Zugewiesen an</label>
                      <div className="mt-1 text-gray-900">{ticket.AssignedUserName}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
