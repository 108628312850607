import React, { useEffect } from 'react';
import Quagga from 'quagga';

const QuaggaScanner = ({ onDetected }) => {
  useEffect(() => {
    Quagga.init({
      inputStream: {
        type: "LiveStream",
        constraints: {
          width: 640,
          height: 480,
          facingMode: "environment" // or user
        }
      },
      decoder: {
        readers: ["code_39_reader"] // Specify Code39 reader
      }
    }, (err) => {
      if (err) {
        console.error(err);
        return;
      }
      Quagga.start();
    });

    Quagga.onDetected((result) => {
      onDetected(result.codeResult.code);
    });

    return () => {
      Quagga.stop();
    };
  }, [onDetected]);

  return <div id="interactive" className="viewport" />;
};

export default QuaggaScanner;
