import React from 'react';
import { ShieldAlert, AlertTriangle, AlertOctagon, HardDrive, Cpu, Shield, Wrench, Activity, Monitor } from 'lucide-react';

export const RiskSummary = ({ summary }) => {
  if (!summary) return null;

  const riskCards = [
    {
      title: "Kritisch",
      value: summary.riskDistribution?.critical || 0,
      icon: AlertOctagon,
      bgColor: "bg-red-50",
      borderColor: "border-red-100",
      textColor: "text-red-700",
      iconColor: "text-red-600"
    },
    {
      title: "Hoch",
      value: summary.riskDistribution?.high || 0,
      icon: AlertTriangle,
      bgColor: "bg-yellow-50",
      borderColor: "border-yellow-100",
      textColor: "text-yellow-700",
      iconColor: "text-yellow-600"
    },
    {
      title: "Mittel",
      value: summary.riskDistribution?.medium || 0,
      icon: ShieldAlert,
      bgColor: "bg-orange-50",
      borderColor: "border-orange-100",
      textColor: "text-orange-700",
      iconColor: "text-orange-600"
    }
  ];

  const issueCards = [
    {
      title: "Hardware",
      value: summary.issueTypes?.hardware || 0,
      icon: HardDrive,
      bgColor: "bg-blue-50",
      borderColor: "border-blue-100",
      textColor: "text-blue-700",
      iconColor: "text-blue-600"
    },
    {
      title: "Performance",
      value: summary.issueTypes?.performance || 0,
      icon: Cpu,
      bgColor: "bg-purple-50",
      borderColor: "border-purple-100",
      textColor: "text-purple-700",
      iconColor: "text-purple-600"
    },
    {
      title: "Sicherheit",
      value: summary.issueTypes?.security || 0,
      icon: Shield,
      bgColor: "bg-green-50",
      borderColor: "border-green-100",
      textColor: "text-green-700",
      iconColor: "text-green-600"
    },
    {
      title: "Service",
      value: summary.issueTypes?.service || 0,
      icon: Wrench,
      bgColor: "bg-indigo-50",
      borderColor: "border-indigo-100",
      textColor: "text-indigo-700",
      iconColor: "text-indigo-600"
    }
  ];

  return (
    <div className="space-y-8">
      {/* Risk Distribution */}
      <div>
        <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2">
          <ShieldAlert className="text-red-500" size={20} />
          Risikoverteilung
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {riskCards.map((card, index) => (
            <div key={index} className={`${card.bgColor} rounded-xl p-4 border ${card.borderColor} transition-all hover:shadow-md`}>
              <div className="flex items-center justify-between mb-2">
                <span className={`text-sm font-medium ${card.textColor}`}>{card.title}</span>
                <card.icon className={`${card.iconColor}`} size={20} />
              </div>
              <div className={`text-2xl font-bold ${card.textColor}`}>
                {card.value}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Issue Types */}
      <div>
        <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center gap-2">
          <AlertTriangle className="text-yellow-500" size={20} />
          Problemkategorien
        </h3>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {issueCards.map((card, index) => (
            <div key={index} className={`${card.bgColor} rounded-xl p-4 border ${card.borderColor} transition-all hover:shadow-md`}>
              <div className="flex items-center justify-between mb-2">
                <span className={`text-sm font-medium ${card.textColor}`}>{card.title}</span>
                <card.icon className={`${card.iconColor}`} size={20} />
              </div>
              <div className={`text-2xl font-bold ${card.textColor}`}>
                {card.value}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Overview */}
      <div className="bg-gray-50 rounded-xl p-6 border border-gray-200 transition-all hover:shadow-md">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <div className="flex items-center gap-2 text-sm text-gray-600 mb-1">
              <Activity className="text-blue-500" size={16} />
              Durchschnittliches Risiko
            </div>
            <div className="text-2xl font-bold text-gray-900">
              {summary.averageRiskScore || 0}
            </div>
          </div>
          <div>
            <div className="flex items-center gap-2 text-sm text-gray-600 mb-1">
              <Monitor className="text-blue-500" size={16} />
              Gesamtgeräte
            </div>
            <div className="text-2xl font-bold text-gray-900">
              {summary.totalDevices || 0}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskSummary;
