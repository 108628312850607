import React from 'react';

export const CustomLegend = ({ payload, activeItems, onItemClick }) => {
  if (!payload || !payload.length) return null;

  return (
    <div className="flex flex-wrap gap-4 mt-2 px-4">
      {payload.map((entry) => {
        const identifier = entry.dataKey;
        
        return (
          <div 
            key={identifier}
            onClick={() => onItemClick(identifier)}
            className={`flex items-center gap-2 cursor-pointer px-2 py-1 rounded transition-colors hover:bg-gray-100
              ${!activeItems.includes(identifier)
                ? 'text-gray-400'
                : 'text-gray-900'}`}
          >
            <div 
              className="w-3 h-3 rounded-full" 
              style={{ backgroundColor: entry.color }}
            />
            <span className="text-sm">{entry.value}</span>
          </div>
        );
      })}
    </div>
  );
};

export default CustomLegend;
