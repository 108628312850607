import React from 'react';
import { Gauge, Clock, AlertOctagon, Building2 } from 'lucide-react';

export const ServiceSection = ({ service }) => {
  if (!service) return null;

  const formatMinutes = (minutes) => {
    if (!minutes || minutes < 0) return 'N/A';
    const days = Math.floor(minutes / 1440);
    const hours = Math.floor((minutes % 1440) / 60);
    const mins = Math.floor(minutes % 60);
    return `${days}d ${hours}h ${mins}m`;
  };

  const getSLAStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'within sla':
        return 'text-green-600';
      case 'sla at risk':
        return 'text-yellow-600';
      default:
        return 'text-red-600';
    }
  };

  return (
    <div className="p-4">
      <h3 className="font-medium text-gray-900 mb-3 flex items-center gap-2">
        <Building2 className="h-4 w-4 text-gray-500" />
        Service Metrics
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Ticket Metrics */}
        <div className="bg-gray-50 rounded-lg p-3">
          <div className="flex items-center justify-between mb-2">
            <div className="text-sm text-gray-500">Recent Tickets</div>
            <AlertOctagon className="h-4 w-4 text-gray-500" />
          </div>
          <div className="text-xl font-bold text-gray-900">
            {service.metrics?.tickets90Days || 0}
          </div>
          <div className="text-xs text-gray-500 mt-1">In last 90 days</div>
          <div className="text-xs text-red-600 mt-1">
            High Severity: {service.metrics?.highSeverityIncidents90Days || 0}
          </div>
        </div>

        {/* SLA Status */}
        <div className="bg-gray-50 rounded-lg p-3">
          <div className="flex items-center justify-between mb-2">
            <div className="text-sm text-gray-500">SLA Status</div>
            <Clock className="h-4 w-4 text-gray-500" />
          </div>
          <div className={`text-xl font-bold ${getSLAStatusColor(service.metrics?.slaStatus)}`}>
            {service.metrics?.slaStatus || 'N/A'}
          </div>
          <div className="text-xs text-gray-500 mt-1">
            Avg Resolution Time: {formatMinutes(service.metrics?.avgResolutionTimeMinutes)}
          </div>
        </div>

        {/* Service Impact */}
        <div className="bg-gray-50 rounded-lg p-3">
          <div className="flex items-center justify-between mb-2">
            <div className="text-sm text-gray-500">Service Impact</div>
            <Gauge className="h-4 w-4 text-gray-500" />
          </div>
          <div className="text-xl font-bold text-gray-900">
            {service.impact?.criticality || 'N/A'}
          </div>
          <div className="text-xs text-gray-500 mt-1">
            Impact Score: {service.impact?.score || 0}
          </div>
          <div className="text-xs text-gray-500 mt-1">
            Quality Score: {service.impact?.serviceQualityScore || 0}
          </div>
        </div>

        {/* Service Agreement */}
        {service.agreement && (
          <div className="md:col-span-3 bg-gray-50 rounded-lg p-3">
            <div className="text-sm text-gray-500 mb-2">Service Agreement</div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <div className="text-xs text-gray-500">Coverage</div>
                <div className="font-medium text-gray-900">
                  {service.agreement.coverage || 'Not Covered'}
                </div>
              </div>
              {service.agreement.hours && (
                <>
                  <div>
                    <div className="text-xs text-gray-500">Contracted Hours</div>
                    <div className="font-medium text-gray-900">
                      {service.agreement.hours.contracted || 'N/A'}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-gray-500">Used Hours</div>
                    <div className="font-medium text-gray-900">
                      {service.agreement.hours.used || 'N/A'}
                      {service.agreement.hours.utilizationPercentage && (
                        <span className="text-xs text-gray-500 ml-1">
                          ({service.agreement.hours.utilizationPercentage}%)
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            {service.impact?.recommendation && (
              <div className="mt-2 text-xs text-red-600">
                {service.impact.recommendation}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceSection;
