import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { 
  ArrowLeft, Server, Cpu, HardDrive, Shield, Network, LucideMemoryStick, Monitor,
  Calendar, Clock, Building, User, Settings, AlertTriangle, CheckCircle,
  Activity, Thermometer, Globe, Database, Download, Terminal, Package,
  BatteryCharging, Boxes, Power, Gauge, FileText, Wifi, WifiOff, Search,
  ChevronLeft, ChevronRight
} from 'lucide-react';
import { apiClient } from '../utils/apiClient';
import PrintLabel from '../components/PrintLabel';
import { MetricsSection } from '../components/metrics/MetricsSection';
import RemotePowershell from '../components/RemotePowershell/RemotePowershell';
import { useAuth } from '../contexts/AuthContext';
import { AssetPDFDocument } from '../components/pdf/AssetPDFDocument';

const STATUS_BADGES = {
  'OK': 'bg-green-100 text-green-800 border-green-200',
  'Threat': 'bg-red-100 text-red-800 border-red-200',
  'Not configured': 'bg-yellow-100 text-yellow-800 border-yellow-200',
};

const StatusBadge = ({ status, size = 'default' }) => {
  const badgeClass = STATUS_BADGES[status] || 'bg-gray-100 text-gray-800 border-gray-200';
  const sizeClasses = {
    'default': 'px-3 py-1 text-sm',
    'large': 'px-4 py-2 text-base'
  };

  const icon = status === 'OK' ? CheckCircle : AlertTriangle;
  const Icon = icon;

  return (
    <span className={`${sizeClasses[size]} font-medium rounded-full flex items-center gap-2 border ${badgeClass}`}>
      <Icon className="w-4 h-4" />
      {status}
    </span>
  );
};

const MetricCard = ({ icon: Icon, title, value, subtitle }) => (
  <div className="bg-white p-4 rounded-xl border hover:shadow-md transition-shadow">
    <div className="flex items-start justify-between">
      <div className="flex-1">
        <p className="text-sm text-gray-500">{title}</p>
        <p className="mt-1 text-xl font-semibold text-gray-900">{value}</p>
        {subtitle && <p className="text-xs text-gray-400 mt-1">{subtitle}</p>}
      </div>
      <Icon className="w-5 h-5 text-gray-400" />
    </div>
  </div>
);

const TabButton = ({ active, icon: Icon, children, onClick, alert }) => (
  <button
    onClick={onClick}
    className={`flex items-center px-4 py-2 rounded-lg transition-colors relative ${
      active 
        ? 'bg-gray-900 text-white' 
        : 'text-gray-600 hover:bg-gray-100'
    }`}
  >
    <Icon className="mr-2 h-4 w-4" />
    {children}
    {alert && (
      <span className="absolute -top-1 -right-1 w-3 h-3 bg-red-500 rounded-full" />
    )}
  </button>
);

const DetailGrid = ({ items }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    {items.map((item, index) => (
      <div key={index} className="bg-white p-4 rounded-lg border hover:shadow-md transition-shadow">
        <div className="flex items-center justify-between gap-2 mb-2">
          <div className="flex items-center gap-2">
            {item.icon && <item.icon className="w-4 h-4 text-gray-400" />}
            <h3 className="text-sm font-medium text-gray-500">{item.label}</h3>
          </div>
        </div>
        <p className="text-gray-900">{item.value || '-'}</p>
        {item.subtitle && <p className="text-xs text-gray-500 mt-1">{item.subtitle}</p>}
        {item.details && (
          <div className="mt-2 space-y-1">
            {item.details.map((detail, idx) => (
              <p key={idx} className="text-xs text-gray-600">{detail}</p>
            ))}
          </div>
        )}
      </div>
    ))}
  </div>
);

const SoftwareList = ({ software }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredSoftware = software.filter(app => 
    app.Application.toLowerCase().includes(searchTerm.toLowerCase()) ||
    app.Publisher.toLowerCase().includes(searchTerm.toLowerCase()) ||
    app.Version.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredSoftware.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedSoftware = filteredSoftware.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4 mb-4">
        <div className="relative flex-1">
          <input
            type="text"
            placeholder="Software durchsuchen..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-gray-900 focus:border-gray-900"
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
        <span className="text-sm text-gray-500">
          {filteredSoftware.length} Programme gefunden
        </span>
      </div>

      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-900 text-white">
            <tr>
              <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold">Anwendung</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold">Version</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold">Hersteller</th>
              <th className="px-3 py-3.5 text-left text-sm font-semibold">Status</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {paginatedSoftware.map((app, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">{app.Application}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{app.Version}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{app.Publisher || 'N/A'}</td>
                <td className="whitespace-nowrap px-3 py-4 text-sm">
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                    app.Visible === 'True' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                  }`}>
                    {app.Visible === 'True' ? 'Aktiv' : 'Inaktiv'}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {totalPages > 1 && (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 rounded-lg mt-4">
          <div className="flex flex-1 justify-between sm:hidden">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Zurück
            </button>
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Weiter
            </button>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Zeige <span className="font-medium">{startIndex + 1}</span> bis{' '}
                <span className="font-medium">
                  {Math.min(startIndex + itemsPerPage, filteredSoftware.length)}
                </span>{' '}
                von <span className="font-medium">{filteredSoftware.length}</span> Einträgen
              </p>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
              >
                <ChevronLeft size={20} />
              </button>
              <button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:cursor-not-allowed"
              >
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ActionHistory = ({ history }) => (
  <div className="space-y-4">
    {history.map((action, index) => (
      <div key={index} className="bg-white p-4 rounded-lg border">
        <div className="flex items-center justify-between">
          <div>
            <p className="font-medium text-gray-900">{action.Description}</p>
            <p className="text-sm text-gray-500">{action.ActionType}</p>
          </div>
          <div className="text-right">
            <p className="text-sm text-gray-900">{new Date(action.ActionedDate).toLocaleString('de-DE')}</p>
            <p className="text-sm text-gray-500">{action.AssignedUserName}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export const AssetDetailPage = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [asset, setAsset] = useState(null);
  const { assetId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const loadAsset = async () => {
      try {
        setLoading(true);
        const response = await apiClient.get(`/customers/${user.naveriskClientId}/devices/${assetId}`);
        setAsset(response);
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (user?.naveriskClientId) {
      loadAsset();
    }
  }, [assetId, user?.naveriskClientId]);

  const formatMemorySlots = (slots) => {
    return slots.map(slot => {
      const parts = slot.split('\n').map(s => s.trim()).filter(Boolean);
      const slotNumber = parts[1];
      const size = parts[3];
      const speed = parts[5];
      return `${slotNumber}: ${size} @ ${speed}MHz`;
    });
  };

  const formatStorageDevices = (devices) => {
    return devices.map(device => {
      const parts = device.split('\n').map(s => s.trim()).filter(Boolean);
      const devicePath = parts[1];
      const model = parts[3];
      const size = parts[5];
      return `${model} (${size})`;
    });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 max-w-7xl mx-auto">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-700">
          <h3 className="font-medium">Fehler</h3>
          <p className="mt-1">{error}</p>
        </div>
      </div>
    );
  }

  if (!asset) {
    return <div className="p-4">Gerät nicht gefunden</div>;
  }

  const metrics = [
    {
      icon: HardDrive,
      title: 'Speicherplatz',
      value: asset.hardware.storage.totalStorage,
      subtitle: `${asset.hardware.storage.devices.length} Laufwerke`,
    },
    {
      icon: LucideMemoryStick,
      title: 'Arbeitsspeicher',
      value: asset.hardware.memory.totalMemory,
      subtitle: `${asset.hardware.memory.slots.length} RAM-Module`,
    },
    {
      icon: Cpu,
      title: 'Prozessor',
      value: asset.hardware.cpu.name,
      subtitle: `${asset.hardware.cpu.clockSpeed}MHz`,
    },
    {
      icon: Calendar,
      title: 'Betriebszeit',
      value: `${asset.system.uptime} Stunden`,
      subtitle: asset.NeedsReboot ? 'Neustart erforderlich' : 'System aktuell',
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4">
        <div className="mb-8">
          <button
            onClick={() => navigate('/assets')}
            className="mb-6 inline-flex items-center text-gray-600 hover:text-gray-900"
          >
            <ArrowLeft className="mr-2 h-5 w-5" />
            Zurück zur Übersicht
          </button>

          <div className="bg-white rounded-xl border p-6 mb-6">
            <div className="flex items-start justify-between mb-4">
              <div>
                <div className="flex items-center gap-3">
                  <Server className="w-8 h-8 text-gray-900" />
                  <div>
                    <h1 className="text-2xl font-bold text-gray-900">{asset.Name}</h1>
                    <p className="text-gray-500">{asset.DeviceTypeName}</p>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-4">
                <PrintLabel asset={asset} />
                <PDFDownloadLink 
                  document={<AssetPDFDocument asset={asset} />}
                  fileName={`${asset.Name}-bericht.pdf`}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <FileText className="w-4 h-4 mr-2" />
                  PDF Export
                </PDFDownloadLink>
                <div className="flex flex-col items-end gap-2">
                  <StatusBadge status={asset.Status} size="large" />
                  {asset.status.online ? 
                    <span className="text-green-600 flex items-center gap-1 text-sm">
                      <Wifi size={14} /> Online
                    </span> :
                    <span className="text-gray-500 flex items-center gap-1 text-sm">
                      <WifiOff size={14} /> Offline
                    </span>
                  }
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {metrics.map((metric, index) => (
                <MetricCard key={index} {...metric} />
              ))}
            </div>
          </div>

          <div className="bg-white p-1 rounded-lg border mb-6 flex space-x-2 overflow-x-auto">
            <TabButton 
              active={activeTab === 'overview'} 
              icon={Server} 
              onClick={() => setActiveTab('overview')}
            >
              Übersicht
            </TabButton>
            <TabButton 
              active={activeTab === 'specs'} 
              icon={Cpu} 
              onClick={() => setActiveTab('specs')}
            >
              Spezifikationen
            </TabButton>
            <TabButton 
              active={activeTab === 'software'} 
              icon={Package} 
              onClick={() => setActiveTab('software')}
            >
              Software
            </TabButton>
            <TabButton 
              active={activeTab === 'history'} 
              icon={Clock} 
              onClick={() => setActiveTab('history')}
            >
              Verlauf
            </TabButton>
            <TabButton 
              active={activeTab === 'metrics'} 
              icon={Activity} 
              onClick={() => setActiveTab('metrics')}
            >
              Metriken
            </TabButton>
            <TabButton 
              active={activeTab === 'powershell'} 
              icon={Terminal} 
              onClick={() => setActiveTab('powershell')}
            >
              PowerShell
            </TabButton>
          </div>

          <div className="bg-white rounded-lg border p-6">
            {activeTab === 'overview' && (
              <DetailGrid
                items={[
                  { 
                    icon: Settings, 
                    label: "Betriebssystem", 
                    value: asset.OperatingSystem,
                    subtitle: asset.system.agentVersion ? `Agent Version: ${asset.system.agentVersion}` : null
                  },
                  { 
                    icon: Globe, 
                    label: "IP-Adressen", 
                    value: "Netzwerk-Konfiguration",
                    details: asset.IPAddress.split(', ')
                  },
                  { 
                    icon: Settings, 
                    label: "MAC-Adressen", 
                    value: "Hardware-Adressen",
                    details: asset.MacAddress.split(', ')
                  },
                  { 
                    icon: Shield, 
                    label: "Antivirus", 
                    value: asset.security.antiVirus.details,
                    subtitle: `Status: ${asset.security.antiVirus.enabled}`
                  },
                  { 
                    icon: Shield, 
                    label: "Anti-Spyware", 
                    value: asset.security.antiSpyware.details,
                    subtitle: `Status: ${asset.security.antiSpyware.enabled}`
                  },
                  { 
                    icon: Building, 
                    label: "Standort", 
                    value: asset.business.location || 'Nicht angegeben',
                    subtitle: `Feature Level: ${asset.business.featureLevel}`
                  },
                  { 
                    icon: User, 
                    label: "Hostname", 
                    value: asset.HostName,
                    subtitle: asset.FQDN
                  },
                  { 
                    icon: Calendar, 
                    label: "System-Daten",
                    value: "Letzte Verbindung",
                    details: [
                      `Verbunden: ${new Date(asset.dates.lastConnected).toLocaleString('de-DE')}`,
                      `Gekauft: ${new Date(asset.dates.purchased).toLocaleDateString('de-DE')}`,
                      `Garantie bis: ${new Date(asset.dates.warrantyExpiry).toLocaleDateString('de-DE')}`,
                    ]
                  }
                ]}
              />
            )}

            {activeTab === 'specs' && (
              <DetailGrid
                items={[
                  { 
                    icon: Cpu, 
                    label: "Prozessor", 
                    value: asset.hardware.cpu.name,
                    details: [
                      `Hersteller: ${asset.hardware.cpu.manufacturer}`,
                      `Taktrate: ${asset.hardware.cpu.clockSpeed}MHz`,
                      `Modell: ${asset.hardware.cpu.model}`
                    ]
                  },
                  { 
                    icon: LucideMemoryStick, 
                    label: "Arbeitsspeicher", 
                    value: asset.hardware.memory.totalMemory,
                    details: formatMemorySlots(asset.hardware.memory.slots)
                  },
                  { 
                    icon: HardDrive, 
                    label: "Speichergeräte", 
                    value: asset.hardware.storage.totalStorage,
                    details: formatStorageDevices(asset.hardware.storage.devices)
                  },
                  { 
                    icon: Building, 
                    label: "Mainboard", 
                    value: asset.hardware.mainboard.manufacturer,
                    subtitle: asset.hardware.mainboard.model
                  },
                  { 
                    icon: Settings, 
                    label: "System", 
                    value: asset.system.manufacturer,
                    details: [
                      `Modell: ${asset.system.model}`,
                      `Beschreibung: ${asset.system.description}`,
                      `Seriennummer: ${asset.system.serialNumber}`
                    ]
                  }
                ]}
              />
            )}

            {activeTab === 'software' && (
              <SoftwareList software={asset.software} />
            )}

            {activeTab === 'history' && (
              <ActionHistory history={asset.actionHistory} />
            )}

            {activeTab === 'metrics' && (
              <MetricsSection assetId={assetId} />
            )}

            {activeTab === 'powershell' && (
              <RemotePowershell assetId={assetId} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetDetailPage;
