import React from 'react';
import { Calendar } from 'lucide-react';
import { TIME_RANGES } from '../constants';
import RefreshSelector from './RefreshSelector';

export const TimeRangeSelector = ({ 
  selectedRange, 
  onRangeChange, 
  onCustomRange, 
  selectedInterval, 
  onIntervalChange 
}) => (
  <div className="flex items-center justify-between mb-6">
    <div className="flex items-center gap-4">
      <div className="flex gap-2">
        {TIME_RANGES.map((range) => (
          <button
            key={range.value}
            onClick={() => onRangeChange(range.value)}
            className={`px-3 py-1.5 rounded-lg border text-sm transition-colors ${
              selectedRange === range.value
                ? 'bg-gray-900 text-white border-gray-900'
                : 'bg-white text-gray-700 border-gray-200 hover:bg-gray-50'
            }`}
          >
            {range.label}
          </button>
        ))}
      </div>
      {selectedRange === 'custom' && (
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <Calendar className="w-4 h-4 text-gray-500" />
            <input
              type="datetime-local"
              onChange={(e) => onCustomRange('start', e.target.value)}
              className="border rounded px-2 py-1 text-sm"
            />
          </div>
          <div className="flex items-center gap-2">
            <Calendar className="w-4 h-4 text-gray-500" />
            <input
              type="datetime-local"
              onChange={(e) => onCustomRange('end', e.target.value)}
              className="border rounded px-2 py-1 text-sm"
            />
          </div>
        </div>
      )}
    </div>
    <RefreshSelector 
      selectedInterval={selectedInterval}
      onIntervalChange={onIntervalChange}
    />
  </div>
);

export default TimeRangeSelector;
