import React, { useState } from 'react';
import { HardDrive, Activity, Shield, Wrench, ChevronRight, AlertTriangle } from 'lucide-react';
import HardwareSection from './HardwareSection';
import PerformanceSection from './PerformanceSection';
import SecuritySection from './SecuritySection';
import ServiceSection from './ServiceSection';

const DeviceDetails = ({ device }) => {
  const [activeTab, setActiveTab] = useState('hardware');

  const tabs = [
    { id: 'hardware', name: 'Hardware', icon: HardDrive },
    { id: 'performance', name: 'Performance', icon: Activity },
    { id: 'security', name: 'Sicherheit', icon: Shield },
    { id: 'service', name: 'Service', icon: Wrench }
  ];

  const renderSection = () => {
    switch (activeTab) {
      case 'hardware':
        return <HardwareSection hardware={device.hardware} />;
      case 'performance':
        return <PerformanceSection performance={device.performance} />;
      case 'security':
        return <SecuritySection security={device.security} />;
      case 'service':
        return <ServiceSection service={device.service} />;
      default:
        return null;
    }
  };

  return (
    <div className="space-y-4">
      {/* Navigation Tabs */}
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => {
            const isActive = activeTab === tab.id;
            return (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`
                  flex items-center gap-2 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium transition-colors
                  ${isActive 
                    ? 'border-blue-500 text-blue-600' 
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}
                `}
              >
                <tab.icon size={16} />
                {tab.name}
              </button>
            );
          })}
        </nav>
      </div>

      {/* Quick Stats */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="bg-blue-50 rounded-xl p-4 border border-blue-100">
          <div className="text-sm text-blue-600 mb-1">CPU Auslastung</div>
          <div className="text-2xl font-bold text-blue-700">
            {device.performance?.resourceUsage?.cpuPattern || 'N/A'}
          </div>
        </div>
        <div className="bg-purple-50 rounded-xl p-4 border border-purple-100">
          <div className="text-sm text-purple-600 mb-1">RAM Auslastung</div>
          <div className="text-2xl font-bold text-purple-700">
            {device.performance?.resourceUsage?.memoryPattern || 'N/A'}
          </div>
        </div>
        <div className="bg-green-50 rounded-xl p-4 border border-green-100">
          <div className="text-sm text-green-600 mb-1">Sicherheitsscore</div>
          <div className="text-2xl font-bold text-green-700">
            {device.security?.scores?.securityScore || 'N/A'}
          </div>
        </div>
        <div className="bg-orange-50 rounded-xl p-4 border border-orange-100">
          <div className="text-sm text-orange-600 mb-1">Performance Score</div>
          <div className="text-2xl font-bold text-orange-700">
            {device.performance?.scores?.performanceScore || 'N/A'}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
        {renderSection()}
      </div>

      {/* Recommendations */}
      {device.riskAssessment?.recommendation && (
        <div className="bg-yellow-50 rounded-xl p-4 border border-yellow-100">
          <div className="flex items-start gap-3">
            <AlertTriangle className="text-yellow-500 mt-0.5" size={20} />
            <div>
              <h4 className="font-medium text-yellow-800">Empfehlung</h4>
              <p className="mt-1 text-sm text-yellow-700">
                {device.riskAssessment.recommendation}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceDetails;
