import React from 'react';
import { Link } from 'react-router-dom';
import { Server, Ticket, FileText, ArrowRight, ShieldCheck, Activity, Database, Lock, Mail, Phone } from 'lucide-react';
import { Helmet

 } from 'react-helmet';
const FeatureCard = ({ icon: Icon, title, description, gradient }) => (
  <div className="group relative overflow-hidden rounded-2xl bg-white p-8 transition-all duration-300 hover:shadow-lg border border-gray-100">
    <div className="relative z-20">
      <div className={`mb-6 inline-block rounded-xl p-3 ${gradient}`}>
        <Icon className="h-6 w-6 text-white" />
      </div>
      <h3 className="mb-4 text-xl font-semibold text-gray-900">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const StatisticBadge = ({ icon: Icon, label }) => (
  <div className="flex items-center gap-2 rounded-full border border-gray-200 bg-white/80 px-4 py-2 text-sm backdrop-blur-sm">
    <Icon className="h-4 w-4 text-blue-600" />
    <span className="text-gray-700">{label}</span>
  </div>
);

const LandingPage = () => {
  return (
    
    <div className="relative min-h-screen bg-gradient-to-b from-gray-50 to-white pb-20">
    <Helmet>
        <title>Kundenportal - Armann Systems GmbH</title>
      </Helmet>  
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#80808008_1px,transparent_1px),linear-gradient(to_bottom,#80808008_1px,transparent_1px)] bg-[size:5rem_5rem]" />
      
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="relative pt-20">
          <div className="absolute left-1/2 top-0 h-[1000px] w-[1000px] -translate-x-1/2 rounded-full bg-gradient-to-tr from-blue-50 to-indigo-50 opacity-40 blur-3xl" />
          
          <div className="relative">
            <div className="mb-16 max-w-4xl">
              <h1 className="mb-8 text-6xl font-bold leading-tight tracking-tight text-gray-900 lg:text-7xl">
                Armann Systems
                <span className="relative mt-2 block">
                  <span className="relative z-10 bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                    Kundenportal
                  </span>
                  <span className="absolute -bottom-1.5 left-0 h-1.5 w-full rounded-full bg-gradient-to-r from-blue-600/20 to-indigo-600/20" />
                </span>
              </h1>
              
              <p className="text-xl leading-relaxed text-gray-600">
                Ihr zentraler Zugang zu Ticket-System, Asset Management und Support-Dokumentation. 
                Entwickelt für effiziente IT-Administration.
              </p>

              <div className="mt-12 flex flex-col gap-6 sm:flex-row sm:items-center">
                <Link 
                  to="/register" 
                  className="group relative inline-flex items-center gap-2 overflow-hidden rounded-full bg-gray-900 px-8 py-4 font-medium text-white transition-all hover:pr-12"
                >
                  Registrierung
                  <ArrowRight className="absolute right-8 h-5 w-5 opacity-0 transition-all group-hover:right-6 group-hover:opacity-100" />
                </Link>
                
                <Link
                  to="/login"
                  className="group inline-flex items-center gap-2 rounded-full border border-gray-300 bg-white px-8 py-4 font-medium text-gray-700 transition-all hover:border-gray-400"
                >
                  <ShieldCheck className="h-5 w-5 text-gray-500" />
                  Anmelden
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="relative mt-32">
          <div className="absolute inset-0 -top-16 bg-gradient-to-b from-transparent via-gray-50/80 to-white" />
          <div className="relative grid gap-8 md:grid-cols-3">
            <FeatureCard
              icon={Ticket}
              title="Ticket-System"
              description="Erstellen und verwalten Sie Support-Anfragen. Übersichtlich, schnell und effizient."
              gradient="bg-blue-600"
            />
            <FeatureCard
              icon={Server}
              title="Asset Management"
              description="Behalten Sie den Überblick über Ihre IT-Infrastruktur. Transparent und strukturiert."
              gradient="bg-blue-600"
            />
            <FeatureCard
              icon={FileText}
              title="Dokumentation"
              description="Zugriff auf relevante Support-Dokumente und technische Anleitungen."
              gradient="bg-blue-600"
            />
          </div>
        </div>

        <div className="mt-32">
          <div className="overflow-hidden rounded-3xl bg-gradient-to-br from-gray-900 to-gray-800 p-1">
            <div className="relative rounded-2xl bg-gray-900 p-8 text-white">
              <div className="relative z-10">
                <h2 className="mb-6 text-2xl font-semibold">Zugang zum Portal</h2>
                <div className="space-y-4 text-gray-300">
                  <p>
                    Die Registrierung ist für bestehende Kunden der Armann Systems GmbH verfügbar. 
                    Melden Sie sich einfach mit Ihrer geschäftlichen E-Mail-Adresse an.
                  </p>
                  <div className="pt-4 space-y-2">
                    <div>
                      <a href="mailto:support@armann-systems.com" className="text-blue-400 hover:text-blue-300">
                        support@armann-systems.com
                      </a>
                    </div>
                    <div>
                      <a href="tel:+4994019179500" className="text-blue-400 hover:text-blue-300">
                        +49 9401 917 91 500
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute inset-0 bg-[linear-gradient(to_right,#ffffff08_1px,transparent_1px),linear-gradient(to_bottom,#ffffff08_1px,transparent_1px)] bg-[size:2rem_2rem]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;