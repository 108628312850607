import React, { useState, useMemo } from 'react';
import { Server, ChevronDown, ChevronLeft, ChevronRight, Monitor, Shield, Activity, Laptop, HardDrive, Cpu, Search, Filter } from 'lucide-react';
import DeviceDetails from './device-sections/DeviceDetails';

const ITEMS_PER_PAGE = 5;

export const DeviceList = ({ devices }) => {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    deviceType: '',
    status: '',
    health: '',
    riskScore: ''
  });

  // Reset to first page when filters change
  React.useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, filters]);

  const getDeviceIcon = (device) => {
    if (device.model?.toLowerCase().includes('virtual')) return Server;
    if (device.operatingSystem?.toLowerCase().includes('server')) return HardDrive;
    return Laptop;
  };

  const getDeviceType = (device) => {
    if (device.model?.toLowerCase().includes('virtual')) return 'Virtual';
    if (device.operatingSystem?.toLowerCase().includes('server')) return 'Server';
    return 'Laptop';
  };

  const getHealthColor = (health) => {
    switch (health?.toLowerCase()) {
      case 'critical':
        return 'bg-red-100 text-red-600 border-red-200';
      case 'warning':
        return 'bg-yellow-100 text-yellow-600 border-yellow-200';
      default:
        return 'bg-green-100 text-green-600 border-green-200';
    }
  };

  const getRiskScoreColor = (score) => {
    if (score >= 70) return 'bg-red-100 text-red-700';
    if (score >= 50) return 'bg-yellow-100 text-yellow-700';
    return 'bg-green-100 text-green-700';
  };

  const getRiskScoreRange = (score) => {
    if (score >= 70) return 'high';
    if (score >= 50) return 'medium';
    return 'low';
  };

  const getDeviceStatus = (device) => {
    const status = device.performance?.status?.connectionStatus;
    switch (status?.toLowerCase()) {
      case 'online':
        return { color: 'bg-green-100 text-green-700', text: 'Online' };
      case 'offline':
        return { color: 'bg-red-100 text-red-700', text: 'Offline' };
      case 'warning':
        return { color: 'bg-yellow-100 text-yellow-700', text: 'Warnung' };
      default:
        return { color: 'bg-gray-100 text-gray-700', text: 'Unbekannt' };
    }
  };

  const filteredDevices = useMemo(() => {
    if (!devices || devices.length === 0) return [];
    
    return devices.filter(device => {
      const matchesSearch = (
        device.deviceName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        device.operatingSystem?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      const matchesDeviceType = !filters.deviceType || getDeviceType(device).toLowerCase() === filters.deviceType.toLowerCase();
      const matchesStatus = !filters.status || getDeviceStatus(device).text.toLowerCase() === filters.status.toLowerCase();
      const matchesHealth = !filters.health || device.hardware?.deviceHealth?.toLowerCase() === filters.health.toLowerCase();
      const matchesRiskScore = !filters.riskScore || getRiskScoreRange(device.riskAssessment?.totalRiskScore) === filters.riskScore;

      return matchesSearch && matchesDeviceType && matchesStatus && matchesHealth && matchesRiskScore;
    });
  }, [devices, searchTerm, filters]);

  const totalPages = Math.ceil(filteredDevices.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentDevices = filteredDevices.slice(startIndex, endIndex);

  if (!devices || devices.length === 0) {
    return (
      <div className="text-center py-8 text-gray-500 bg-white rounded-xl border border-gray-200">
        <Server className="mx-auto h-12 w-12 text-gray-400 mb-3" />
        <h3 className="text-sm font-medium">Keine Geräte gefunden</h3>
        <p className="mt-1 text-sm text-gray-500">Es wurden keine Geräte in Ihrem System gefunden.</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Search and Filters */}
      <div className="bg-white p-4 rounded-xl border border-gray-200 space-y-4">
        <div className="flex gap-4">
          {/* Search */}
          <div className="flex-1 relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Search className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Nach Gerätename oder Betriebssystem suchen..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Filters */}
          <select
            className="block w-40 pl-3 pr-10 py-2 text-base text-gray-500 border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            value={filters.deviceType}
            onChange={(e) => setFilters(f => ({ ...f, deviceType: e.target.value }))}
          >
            <option value="">Alle Gerätetypen</option>
            <option value="Virtual">Virtual</option>
            <option value="Server">Server</option>
            <option value="Laptop">Laptop</option>
          </select>

          <select
            className="block w-40 pl-3 pr-10 py-2 text-base text-gray-500 border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            value={filters.status}
            onChange={(e) => setFilters(f => ({ ...f, status: e.target.value }))}
          >
            <option value="">Alle Status</option>
            <option value="online">Online</option>
            <option value="offline">Offline</option>
            <option value="warnung">Warnung</option>
          </select>

          <select
            className="block w-40 pl-3 pr-10 py-2 text-base text-gray-500 border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            value={filters.health}
            onChange={(e) => setFilters(f => ({ ...f, health: e.target.value }))}
          >
            <option value="">Alle Health</option>
            <option value="critical">Critical</option>
            <option value="warning">Warning</option>
            <option value="good">Good</option>
          </select>

          <select
            className="block w-40 pl-3 pr-10 py-2 text-base text-gray-500 border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            value={filters.riskScore}
            onChange={(e) => setFilters(f => ({ ...f, riskScore: e.target.value }))}
          >
            <option value="">Alle Risiken</option>
            <option value="high">Hoch (≥70)</option>
            <option value="medium">Mittel (50-69)</option>
            <option value="low">Niedrig (&lt;50)</option>
          </select>
        </div>
      </div>

      {/* Devices */}
      <div className="space-y-4">
        {currentDevices.length === 0 ? (
          <div className="text-center py-8 text-gray-500 bg-white rounded-xl border border-gray-200">
            <Filter className="mx-auto h-12 w-12 text-gray-400 mb-3" />
            <h3 className="text-sm font-medium">Keine Geräte gefunden</h3>
            <p className="mt-1 text-sm text-gray-500">Keine Geräte entsprechen den ausgewählten Filtern.</p>
          </div>
        ) : (
          currentDevices.map((device) => {
            const DeviceIcon = getDeviceIcon(device);
            const healthColor = getHealthColor(device.hardware?.deviceHealth);
            const riskScoreColor = getRiskScoreColor(device.riskAssessment?.totalRiskScore);
            const status = getDeviceStatus(device);

            return (
              <div key={device.deviceId} className="border border-gray-200 rounded-xl overflow-hidden bg-white transition-all hover:shadow-md">
                <div 
                  className="p-4 hover:bg-gray-50 cursor-pointer transition-colors"
                  onClick={() => setSelectedDevice(selectedDevice === device.deviceId ? null : device.deviceId)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      <div className={`p-2 rounded-lg ${healthColor}`}>
                        <DeviceIcon size={20} />
                      </div>
                      <div>
                        <div className="font-medium text-gray-900">{device.deviceName || 'Unbenanntes Gerät'}</div>
                        <div className="text-sm text-gray-500 flex items-center gap-2">
                          <span>{device.operatingSystem || 'Unbekanntes Betriebssystem'}</span>
                          <span className="inline-block w-1 h-1 bg-gray-300 rounded-full"></span>
                          <span className={`px-2 py-0.5 rounded-full text-xs ${status.color}`}>
                            {status.text}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-4">
                      <div className="flex items-center gap-2">
                        <Cpu className="text-gray-400" size={16} />
                        <span className="text-sm text-gray-500">
                          {device.hardware?.cpu?.type?.trim().split('\n')[0]?.slice(0, 30) || 'Unbekannte CPU'}
                        </span>
                      </div>
                      <div className={`text-sm font-medium px-3 py-1 rounded-full ${riskScoreColor}`}>
                        Risiko: {device.riskAssessment?.totalRiskScore || 0}
                      </div>
                      <ChevronDown 
                        className={`h-5 w-5 text-gray-500 transform transition-transform ${
                          selectedDevice === device.deviceId ? 'rotate-180' : ''
                        }`} 
                      />
                    </div>
                  </div>
                </div>
                
                {selectedDevice === device.deviceId && (
                  <div className="border-t border-gray-200 p-4 bg-gray-50">
                    <DeviceDetails device={device} />
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 rounded-xl mt-4">
          <div className="flex flex-1 justify-between sm:hidden">
            <button
              onClick={() => setCurrentPage(page => Math.max(page - 1, 1))}
              disabled={currentPage === 1}
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
            >
              Zurück
            </button>
            <button
              onClick={() => setCurrentPage(page => Math.min(page + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
            >
              Weiter
            </button>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Zeige <span className="font-medium">{startIndex + 1}</span> bis{' '}
                <span className="font-medium">{Math.min(endIndex, filteredDevices.length)}</span> von{' '}
                <span className="font-medium">{filteredDevices.length}</span> Geräten
              </p>
            </div>
            <div>
              <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                <button
                  onClick={() => setCurrentPage(page => Math.max(page - 1, 1))}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50"
                >
                  <span className="sr-only">Zurück</span>
                  <ChevronLeft className="h-5 w-5" aria-hidden="true" />
                </button>
                {/* Page numbers */}
                {[...Array(totalPages)].map((_, idx) => (
                  <button
                    key={idx}
                    onClick={() => setCurrentPage(idx + 1)}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                      currentPage === idx + 1
                        ? 'z-10 bg-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
                        : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                    }`}
                  >
                    {idx + 1}
                  </button>
                ))}
                <button
                  onClick={() => setCurrentPage(page => Math.min(page + 1, totalPages))}
                  disabled={currentPage === totalPages}
                  className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50"
                >
                  <span className="sr-only">Weiter</span>
                  <ChevronRight className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceList;
