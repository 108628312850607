import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { apiClient } from '../../utils/apiClient';
import { TIME_RANGES } from './constants';
import { formatters } from './formatters';
import ChartLoader from './components/ChartLoader';
import TimeRangeSelector from './components/TimeRangeSelector';
import MetricChart from './components/MetricChart';
import { MEMORY_LABELS } from './constants';

export const MetricsSection = ({ assetId }) => {
  const [timeRange, setTimeRange] = useState('24h');
  const [customRange, setCustomRange] = useState({ start: null, end: null });
  const [refreshInterval, setRefreshInterval] = useState(0);
  const [metrics, setMetrics] = useState({
    cpu: [],
    ram: [],
    network: []
  });
  const [lastUpdateTime, setLastUpdateTime] = useState(null);

  const processRamData = useCallback((data) => {
    if (!data || !data.length) return [];
    
    return data.map(item => ({
      timestamp: item.timestamp,
      total_ram_gb: item.total_ram_bytes / (1024 * 1024 * 1024),
      used_ram_gb: item.used_ram_bytes / (1024 * 1024 * 1024),
      free_ram_gb: item.free_ram_bytes / (1024 * 1024 * 1024),
      swap_used_gb: item.swap_used_bytes / (1024 * 1024 * 1024)
    }));
  }, []);

  const processNetworkData = useCallback((data) => {
    if (!data || !data.length) return [];
    
    return data.reduce((acc, curr) => {
      const existing = acc.find(item => item.timestamp === curr.timestamp);
      if (existing) {
        existing[curr.interface_name] = curr.bandwidth_mbps;
      } else {
        acc.push({
          timestamp: curr.timestamp,
          [curr.interface_name]: curr.bandwidth_mbps
        });
      }
      return acc;
    }, []);
  }, []);

  const getTimeRange = useCallback(() => {
    if (timeRange === 'custom') {
      return {
        startTime: customRange.start,
        endTime: customRange.end
      };
    }

    const range = TIME_RANGES.find(r => r.value === timeRange);
    if (!range) {
      console.error('Invalid time range:', timeRange);
      return null;
    }

    return {
      endTime: new Date().toISOString(),
      startTime: new Date(Date.now() - range.ms).toISOString()
    };
  }, [timeRange, customRange]);

  const fetchFullMetrics = useCallback(async () => {
    const timeRange = getTimeRange();
    if (!timeRange) return;

    try {
      const [cpuData, ramData, networkData] = await Promise.all([
        apiClient.get(`/metrics/cpu/${assetId}?startTime=${timeRange.startTime}&endTime=${timeRange.endTime}`),
        apiClient.get(`/metrics/ram/${assetId}?startTime=${timeRange.startTime}&endTime=${timeRange.endTime}`),
        apiClient.get(`/metrics/network/${assetId}?startTime=${timeRange.startTime}&endTime=${timeRange.endTime}`)
      ]);

      const processedData = {
        cpu: cpuData.metrics || [],
        ram: processRamData(ramData.metrics || []),
        network: processNetworkData(networkData.metrics || [])
      };

      if (processedData.cpu.length || processedData.ram.length || processedData.network.length) {
        setMetrics(processedData);
        setLastUpdateTime(timeRange.endTime);
      }
    } catch (error) {
      console.error('Error fetching full metrics:', error);
    }
  }, [assetId, getTimeRange, processRamData, processNetworkData]);

  const mergeMetricsData = useCallback((oldData, newData, timeRangeMs) => {
    if (!oldData || !oldData.length) return newData || [];
    if (!newData || !newData.length) return oldData;

    // Create a map of existing timestamps for fast lookup
    const existingMap = new Map(
      oldData.map(item => [item.timestamp, item])
    );

    // Add or update with new data
    newData.forEach(item => {
      existingMap.set(item.timestamp, item);
    });

    // Convert back to array, sort, and filter by time range
    const cutoffTime = new Date(Date.now() - timeRangeMs);
    return Array.from(existingMap.values())
      .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
      .filter(item => new Date(item.timestamp) > cutoffTime);
  }, []);

  const fetchIncrementalMetrics = useCallback(async () => {
    if (!lastUpdateTime) {
      await fetchFullMetrics();
      return;
    }

    // Get current time range configuration
    const currentTimeRange = getTimeRange();
    if (!currentTimeRange) return;

    const range = TIME_RANGES.find(r => r.value === timeRange);
    if (!range) return;

    // Calculate the overlap time - use 60 seconds for short intervals, longer for bigger intervals
    const overlapMs = Math.min(60000, range.ms * 0.1); // 10% of the time range or 60 seconds, whichever is smaller
    
    // Fetch with overlap to ensure we don't miss any data points
    const startTime = new Date(new Date(lastUpdateTime).getTime() - overlapMs).toISOString();
    const endTime = currentTimeRange.endTime;

    try {
      const [cpuData, ramData, networkData] = await Promise.all([
        apiClient.get(`/metrics/cpu/${assetId}?startTime=${startTime}&endTime=${endTime}`),
        apiClient.get(`/metrics/ram/${assetId}?startTime=${startTime}&endTime=${endTime}`),
        apiClient.get(`/metrics/network/${assetId}?startTime=${startTime}&endTime=${endTime}`)
      ]);

      setMetrics(prevMetrics => {
        // For network data, we need special handling due to dynamic interface names
        const mergeNetworkData = (oldData, newData) => {
          const processed = processNetworkData(newData || []);
          return mergeMetricsData(oldData, processed, range.ms);
        };

        const newMetrics = {
          cpu: mergeMetricsData(prevMetrics.cpu, cpuData.metrics || [], range.ms),
          ram: mergeMetricsData(prevMetrics.ram, processRamData(ramData.metrics || []), range.ms),
          network: mergeNetworkData(prevMetrics.network, networkData.metrics)
        };

        // Only update if we have new data
        if (newMetrics.cpu.length || newMetrics.ram.length || newMetrics.network.length) {
          return newMetrics;
        }
        return prevMetrics;
      });

      setLastUpdateTime(endTime);
    } catch (error) {
      console.error('Error fetching incremental metrics:', error);
    }
  }, [lastUpdateTime, timeRange, assetId, getTimeRange, processRamData, processNetworkData, mergeMetricsData, fetchFullMetrics]);

  // Effect for initial load and time range changes
  useEffect(() => {
    if (timeRange === 'custom' && (!customRange.start || !customRange.end)) {
      return;
    }
    
    // Reset metrics when changing time range to avoid showing stale data
    setMetrics({
      cpu: [],
      ram: [],
      network: []
    });
    setLastUpdateTime(null);
    
    fetchFullMetrics();
  }, [timeRange, customRange, fetchFullMetrics]);

  // Effect for auto-refresh
  useEffect(() => {
    let intervalId;
    
    if (refreshInterval > 0) {
      intervalId = setInterval(fetchIncrementalMetrics, refreshInterval);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [refreshInterval, fetchIncrementalMetrics]);

  const handleCustomRange = (type, value) => {
    setCustomRange(prev => ({
      ...prev,
      [type]: value
    }));
  };

  return (
    <div className="space-y-6">
      <TimeRangeSelector 
        selectedRange={timeRange}
        onRangeChange={setTimeRange}
        onCustomRange={handleCustomRange}
        selectedInterval={refreshInterval}
        onIntervalChange={setRefreshInterval}
      />

      <div className="grid grid-cols-1 gap-6">
        <Suspense fallback={<ChartLoader />}>
          <MetricChart
            data={metrics.cpu}
            title="CPU-Auslastung"
            dataKey="cpu_usage_percent"
            valueFormatter={formatters.cpu}
            yAxisLabel="CPU-Auslastung (%)"
            metricName="CPU-Auslastung"
            dataLabels={{ cpu_usage_percent: 'CPU-Auslastung' }}
          />
        </Suspense>

        <Suspense fallback={<ChartLoader />}>
          <MetricChart
            data={metrics.ram}
            title="Arbeitsspeicher"
            dataKey="memory"
            valueFormatter={formatters.ram}
            yAxisLabel="Arbeitsspeicher (GB)"
            metricName="Arbeitsspeicher"
            showMultipleLines={true}
            dataLabels={MEMORY_LABELS}
          />
        </Suspense>

        <Suspense fallback={<ChartLoader />}>
          <MetricChart
            data={metrics.network}
            title="Netzwerkdurchsatz"
            valueFormatter={formatters.network}
            yAxisLabel="Durchsatz (Mbps)"
            metricName="Netzwerkdurchsatz"
            showMultipleLines={true}
          />
        </Suspense>
      </div>
    </div>
  );
};

export default MetricsSection;
