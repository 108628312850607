import React from 'react';
import { Ticket, AlertCircle, BarChart3, ArrowUpRight, ArrowDownRight } from 'lucide-react';

export const TicketOverview = ({ stats }) => {
  if (!stats) return null;

  const statCards = [
    {
      title: "Aktive Tickets",
      value: stats.activeTickets?.value || 0,
      change: stats.activeTickets?.change || 0,
      isIncrease: stats.activeTickets?.isIncrease,
      icon: Ticket,
      color: "blue"
    },
    {
      title: "Neue Tickets",
      value: stats.newTickets?.value || 0,
      change: stats.newTickets?.change || 0,
      isIncrease: stats.newTickets?.isIncrease,
      icon: AlertCircle,
      color: "green"
    },
    {
      title: "Gesamt Tickets",
      value: stats.totalTickets?.value || 0,
      change: stats.totalTickets?.change || 0,
      isIncrease: stats.totalTickets?.isIncrease,
      icon: BarChart3,
      color: "purple"
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {statCards.map((stat, index) => (
        <div key={index} className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
          <div className="flex items-center justify-between mb-4">
            <span className="text-gray-500 font-medium">{stat.title}</span>
            <stat.icon className={`text-${stat.color}-600`} size={20} />
          </div>
          <div className="flex items-center justify-between">
            <span className="text-2xl font-bold text-gray-900">{stat.value}</span>
            {stat.change !== 0 && (
              <div className={`flex items-center ${
                stat.isIncrease ? 'text-green-600' : 'text-red-600'
              }`}>
                {stat.isIncrease ? (
                  <ArrowUpRight size={20} />
                ) : (
                  <ArrowDownRight size={20} />
                )}
                <span className="ml-1 text-sm font-medium">{stat.change}%</span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TicketOverview;
