import React, { createContext, useState, useEffect } from 'react';
import { apiClient } from '../utils/apiClient';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState([]);
  const [oldNotifications, setOldNotifications] = useState([]);

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await apiClient.get('/alerts/rmm_alerts');
        const fetchedAlerts = response.rmm_alerts || [];

        const storedAlerts = JSON.parse(localStorage.getItem('alerts')) || [];
        const newAlerts = fetchedAlerts.filter(alert => 
          !storedAlerts.some(storedAlert => storedAlert.id === alert.id)
        );

        setNotifications(fetchedAlerts);
        setNewNotifications(newAlerts);
        setOldNotifications(storedAlerts);

        localStorage.setItem('alerts', JSON.stringify(fetchedAlerts));
      } catch (error) {
        console.error('Fehler beim Abrufen der Warnungen:', error);
      }
    };

    fetchAlerts();
  }, []);

  const markNotificationsAsRead = () => {
    setNewNotifications([]);
  };

  return (
    <NotificationContext.Provider value={{ notifications, newNotifications, oldNotifications, markNotificationsAsRead }}>
      {children}
    </NotificationContext.Provider>
  );
};
