import React from 'react';
import { Activity, Clock, AlertCircle, Gauge } from 'lucide-react';

export const PerformanceSection = ({ performance }) => {
  if (!performance) return null;

  const formatUptime = (seconds) => {
    if (!seconds) return 'N/A';
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${days}d ${hours}h ${minutes}m`;
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'online':
        return 'text-green-600';
      case 'warning':
        return 'text-yellow-600';
      case 'offline':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  return (
    <div className="p-4 border-b border-gray-200">
      <h3 className="font-medium text-gray-900 mb-3 flex items-center gap-2">
        <Activity className="h-4 w-4 text-gray-500" />
        Performance Metrics
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Status Overview */}
        <div className="bg-gray-50 rounded-lg p-3">
          <div className="flex items-center justify-between mb-2">
            <div className="text-sm text-gray-500">Performance Score</div>
            <Gauge className="h-4 w-4 text-gray-500" />
          </div>
          <div className="text-xl font-bold text-gray-900">
            {performance.scores?.performanceScore || 0}
          </div>
          <div className="text-xs text-gray-500 mt-1">
            {performance.scores?.recommendation || 'No recommendation available'}
          </div>
        </div>

        {/* Connection Status */}
        <div className="bg-gray-50 rounded-lg p-3">
          <div className="flex items-center justify-between mb-2">
            <div className="text-sm text-gray-500">Connection Status</div>
            <AlertCircle className="h-4 w-4 text-gray-500" />
          </div>
          <div className={`text-xl font-bold ${getStatusColor(performance.status?.connectionStatus)}`}>
            {performance.status?.connectionStatus || 'Unknown'}
          </div>
          <div className="text-xs text-gray-500 mt-1">
            Last seen: {performance.status?.lastConnection || 0} minutes ago
          </div>
        </div>

        {/* Uptime */}
        <div className="bg-gray-50 rounded-lg p-3">
          <div className="flex items-center justify-between mb-2">
            <div className="text-sm text-gray-500">Uptime</div>
            <Clock className="h-4 w-4 text-gray-500" />
          </div>
          <div className="text-xl font-bold text-gray-900">
            {formatUptime(performance.status?.uptime)}
          </div>
        </div>

        {/* Resource Usage */}
        {performance.resourceUsage && (
          <div className="md:col-span-3 grid grid-cols-1 md:grid-cols-4 gap-4 mt-2">
            <div className="bg-gray-50 rounded-lg p-3">
              <div className="text-sm text-gray-500 mb-1">CPU Pattern</div>
              <div className="font-medium text-gray-900">
                {performance.resourceUsage.cpuPattern || 'N/A'}
              </div>
              <div className="text-xs text-gray-500 mt-1">
                Incidents: {performance.resourceUsage.totalIncidents?.cpu || 0}
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg p-3">
              <div className="text-sm text-gray-500 mb-1">Memory Pattern</div>
              <div className="font-medium text-gray-900">
                {performance.resourceUsage.memoryPattern || 'N/A'}
              </div>
              <div className="text-xs text-gray-500 mt-1">
                Incidents: {performance.resourceUsage.totalIncidents?.memory || 0}
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg p-3">
              <div className="text-sm text-gray-500 mb-1">Storage Incidents</div>
              <div className="font-medium text-gray-900">
                {performance.resourceUsage.totalIncidents?.storage || 0}
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg p-3">
              <div className="text-sm text-gray-500 mb-1">Network Incidents</div>
              <div className="font-medium text-gray-900">
                {performance.resourceUsage.totalIncidents?.network || 0}
              </div>
            </div>
          </div>
        )}

        {/* Performance Trends */}
        {performance.trends && (
          <div className="md:col-span-3 bg-gray-50 rounded-lg p-3">
            <div className="text-sm text-gray-500 mb-2">Performance Trends</div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <div className="text-xs text-gray-500">Average Monthly Incidents</div>
                <div className="font-medium text-gray-900">
                  {performance.trends.averageMonthlyIncidents || 0}
                </div>
              </div>
              <div>
                <div className="text-xs text-gray-500">Peak Monthly Incidents</div>
                <div className="font-medium text-gray-900">
                  {performance.trends.peakMonthlyIncidents || 0}
                </div>
              </div>
              <div>
                <div className="text-xs text-gray-500">Trend</div>
                <div className="font-medium text-gray-900">
                  {performance.trends.trend || 'N/A'}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PerformanceSection;
