import React from 'react';
import { Shield, AlertTriangle, Lock, CheckCircle2, XCircle } from 'lucide-react';

export const SecuritySection = ({ security }) => {
  if (!security) return null;

  const getProtectionStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'protected':
        return 'text-green-600';
      case 'partially protected':
        return 'text-yellow-600';
      case 'unprotected':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  };

  const getRiskLevelColor = (level) => {
    switch (level?.toUpperCase()) {
      case 'HIGH':
        return 'text-red-600';
      case 'MEDIUM':
        return 'text-yellow-600';
      case 'LOW':
        return 'text-green-600';
      default:
        return 'text-gray-600';
    }
  };

  return (
    <div className="p-4 border-b border-gray-200">
      <h3 className="font-medium text-gray-900 mb-3 flex items-center gap-2">
        <Shield className="h-4 w-4 text-gray-500" />
        Security Status
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Protection Status */}
        <div className="bg-gray-50 rounded-lg p-3">
          <div className="flex items-center justify-between mb-2">
            <div className="text-sm text-gray-500">Protection Status</div>
            {security.protection?.status === 'Protected' ? (
              <CheckCircle2 className="h-4 w-4 text-green-500" />
            ) : (
              <XCircle className="h-4 w-4 text-red-500" />
            )}
          </div>
          <div className={`text-xl font-bold ${getProtectionStatusColor(security.protection?.status)}`}>
            {security.protection?.status || 'Unknown'}
          </div>
          <div className="mt-2 space-y-1">
            {security.protection?.antivirus?.enabled && (
              <div className="text-xs">
                <span className="text-gray-500">Antivirus: </span>
                <span className="text-gray-900">{security.protection.antivirus.enabled}</span>
              </div>
            )}
            {security.protection?.firewall?.enabled && (
              <div className="text-xs">
                <span className="text-gray-500">Firewall: </span>
                <span className="text-gray-900">{security.protection.firewall.enabled}</span>
              </div>
            )}
          </div>
        </div>

        {/* Security Score */}
        <div className="bg-gray-50 rounded-lg p-3">
          <div className="flex items-center justify-between mb-2">
            <div className="text-sm text-gray-500">Security Score</div>
            <Lock className="h-4 w-4 text-gray-500" />
          </div>
          <div className="text-xl font-bold text-gray-900">
            {security.scores?.securityScore || 0}
          </div>
          <div className="flex items-center gap-1 mt-1">
            <div className="text-xs text-gray-500">Risk Level:</div>
            <div className={`text-xs font-medium ${getRiskLevelColor(security.scores?.riskLevel)}`}>
              {security.scores?.riskLevel || 'Unknown'}
            </div>
          </div>
          <div className="text-xs text-gray-500 mt-1">
            {security.scores?.recommendation || 'No recommendation available'}
          </div>
        </div>

        {/* Security Incidents */}
        <div className="bg-gray-50 rounded-lg p-3">
          <div className="flex items-center justify-between mb-2">
            <div className="text-sm text-gray-500">Security Incidents</div>
            <AlertTriangle className="h-4 w-4 text-gray-500" />
          </div>
          <div className="text-xl font-bold text-gray-900">
            {security.incidents?.security90Days || 0}
          </div>
          <div className="text-xs text-gray-500 mt-1">In last 90 days</div>
          <div className="text-xs text-gray-500 mt-1">
            Failed Logins: {security.incidents?.failedLogins90Days || 0}
          </div>
        </div>

        {/* Compliance Status */}
        {security.compliance && (
          <div className="md:col-span-3 bg-gray-50 rounded-lg p-3">
            <div className="text-sm text-gray-500 mb-2">Compliance Status</div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div>
                <div className="text-xs text-gray-500">Service Agreement</div>
                <div className="font-medium text-gray-900">
                  {security.compliance.hasServiceAgreement ? 'Yes' : 'No'}
                </div>
              </div>
              <div>
                <div className="text-xs text-gray-500">Assigned Roles</div>
                <div className="font-medium text-gray-900">
                  {security.compliance.assignedRoles || 0}
                </div>
              </div>
              <div>
                <div className="text-xs text-gray-500">Business Systems</div>
                <div className="font-medium text-gray-900">
                  {security.compliance.businessSystemMemberships || 0}
                </div>
              </div>
              <div>
                <div className="text-xs text-gray-500">Issues</div>
                <div className="font-medium text-red-600 text-xs">
                  {security.compliance.issues || 'None'}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SecuritySection;
