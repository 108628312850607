import React from 'react';
import { formatters } from '../formatters';

export const CustomTooltip = ({ active, payload, label, valueFormatter, metricName, dataLabels }) => {
  if (!active || !payload || !payload.length) return null;

  return (
    <div className="bg-white p-3 border border-gray-200 rounded-lg shadow-lg">
      <p className="text-sm text-gray-600 mb-2 font-medium">
        {formatters.tooltipTimestamp(label)}
      </p>
      {payload
        .filter(entry => entry.value !== undefined)
        .map((entry, index) => (
          <p key={index} className="text-sm flex items-center gap-2 py-0.5">
            <span 
              className="w-2 h-2 rounded-full" 
              style={{ backgroundColor: entry.color }}
            />
            <span className="font-medium text-gray-900">
              {dataLabels?.[entry.dataKey] || entry.name || metricName}: {valueFormatter(entry.value)}
            </span>
          </p>
        ))}
    </div>
  );
};

export default CustomTooltip;
