import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from '../contexts/AuthContext';
import { apiClient } from '../utils/apiClient';
import { LoaderContainer } from '../components/Loader';
import { toast } from 'react-toastify';
import { BarChart3, RefreshCw, AlertCircle } from 'lucide-react';

// Import our modular components
import TicketOverview from '../components/statistics/TicketOverview';
import RiskSummary from '../components/statistics/RiskSummary';
import DeviceList from '../components/statistics/DeviceList';

// Import utilities
import { safeGet } from '../utils/formatters';

export const StatisticsPage = () => {
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  const loadInsights = async (showRefresh = false) => {
    try {
      if (showRefresh) setRefreshing(true);
      else setLoading(true);
      setError(null);
      
      const response = await apiClient.get(`/statistics/insights/${user.naveriskClientId}`);
      
      if (response.success) {
        // Process the nested structure
        const processedData = {
          ...response,
          stats: response.stats || {},
          riskAssessment: response.riskAssessment?.riskAssessment || {
            summary: {
              totalDevices: 0,
              riskDistribution: { critical: 0, high: 0, medium: 0 },
              issueTypes: { hardware: 0, performance: 0, security: 0, service: 0 },
              averageRiskScore: 0
            },
            devices: []
          }
        };
        
        setInsights(processedData);
        if (showRefresh) toast.success('Daten erfolgreich aktualisiert');
      } else {
        throw new Error(response.message || 'Fehler beim Laden der Daten');
      }
    } catch (err) {
      console.error('Error loading insights:', err);
      setError(err.message || 'Fehler beim Laden der Insights');
      toast.error('Fehler beim Laden der Insights');
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  // Auto-refresh every 5 minutes
  useEffect(() => {
    if (user?.naveriskClientId) {
      loadInsights();
      const interval = setInterval(() => loadInsights(true), 300000);
      return () => clearInterval(interval);
    }
  }, [user?.naveriskClientId]);

  if (loading) {
    return <LoaderContainer text="Lade System Insights..." />;
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200 max-w-lg w-full mx-4">
          <div className="flex items-center gap-3 mb-4">
            <AlertCircle className="h-8 w-8 text-red-500" />
            <h2 className="text-xl font-semibold text-gray-900">Fehler beim Laden</h2>
          </div>
          <p className="text-gray-500 mb-4">{error}</p>
          <button
            onClick={() => loadInsights()}
            className="w-full inline-flex items-center justify-center gap-2 rounded-lg border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <RefreshCw className="h-4 w-4" />
            Erneut versuchen
          </button>
        </div>
      </div>
    );
  }

  // Early return if no insights data
  if (!insights) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-900">Keine Daten verfügbar</h2>
          <p className="mt-2 text-gray-500">Bitte versuchen Sie es später erneut.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Helmet>
        <title>System Insights - Armann Systems GmbH</title>
      </Helmet>

      <div className="max-w-[1600px] mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="mb-8 bg-white rounded-xl shadow-sm p-6 border border-gray-200">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
            <div>
              <div className="flex items-center gap-3">
                <BarChart3 className="h-6 w-6 text-blue-600" />
                <h1 className="text-2xl font-bold text-gray-900">System Insights</h1>
              </div>
              <p className="mt-1 text-sm text-gray-500">
                Detaillierte Systemanalyse und Leistungsüberwachung
              </p>
            </div>
            
            <button
              onClick={() => loadInsights(true)}
              disabled={refreshing}
              className="inline-flex items-center gap-2 rounded-lg border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 disabled:opacity-50"
            >
              <RefreshCw className={`h-4 w-4 ${refreshing ? 'animate-spin' : ''}`} />
              Aktualisieren
            </button>
          </div>

          {/* Ticket Overview */}
          <div className="mt-6">
            <TicketOverview stats={insights.stats} />
          </div>

          {/* Risk Assessment Summary */}
          <div className="mt-6">
            <RiskSummary summary={insights.riskAssessment?.summary} />
          </div>
        </div>

        {/* Device Analysis */}
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Geräteanalyse</h2>
          <DeviceList 
            devices={insights.riskAssessment?.devices || []}
          />
        </div>

        {/* Summary Footer - Optional additional statistics */}
        {insights.riskAssessment?.summary && (
          <div className="mt-8 text-sm text-gray-500 text-center">
            Gesamtgeräte: {insights.riskAssessment.summary.totalDevices} | 
            Durchschnittliches Risiko: {insights.riskAssessment.summary.averageRiskScore} | 
            Letzte Aktualisierung: {new Date().toLocaleString('de-DE')}
          </div>
        )}
      </div>
    </div>
  );
};

export default StatisticsPage;
