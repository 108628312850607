import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { LoaderWithText } from '../components/Loader';
import { Mail, UserPlus, ArrowLeft } from 'lucide-react';
import { toast } from 'react-toastify';

import Logo from '../images/armann-systems-logo-single.png';

export const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const { register } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await register(email);
      toast.success('Registrierung erfolgreich. Bitte überprüfen Sie Ihre E-Mail, um Ihr Konto zu verifizieren.');
      navigate('/login');
    } catch (error) {
      // Error wird bereits im AuthContext behandelt
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* Logo */}
          <img
            className="mx-auto h-12 w-auto"
            src={Logo}
            alt="Armann Systems"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Account erstellen
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Oder{' '}
            <Link to="/login" className="font-medium text-blue-600 hover:text-blue-700">
              melden Sie sich mit Ihrem bestehenden Account an
            </Link>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-8 shadow-sm sm:rounded-xl sm:px-12 border border-gray-100">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  E-Mail Adresse
                </label>
                <div className="relative mt-2">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <Mail className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full rounded-lg border border-gray-200 py-2.5 pl-10 pr-3 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="name@firma.de"
                  />
                </div>
              </div>

              <div className="flex items-center">
                <input
                  id="terms"
                  name="terms"
                  type="checkbox"
                  required
                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <label htmlFor="terms" className="ml-2 block text-sm text-gray-700">
                  Ich stimme den{' '}
                  <a href="#" className="font-medium text-blue-600 hover:text-blue-700">
                    Nutzungsbedingungen
                  </a>
                  {' '}und der{' '}
                  <a href="#" className="font-medium text-blue-600 hover:text-blue-700">
                    Datenschutzerklärung
                  </a>
                  {' '}zu
                </label>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loading}
                  className="flex w-full justify-center items-center rounded-lg bg-blue-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                >
                  {loading ? (
                    <LoaderWithText text="Account wird erstellt..." size="small" className="text-white" />
                  ) : (
                    <>
                      <UserPlus className="mr-2 h-5 w-5" />
                      Account erstellen
                    </>
                  )}
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">
                    oder
                  </span>
                </div>
              </div>

              <div className="mt-6 text-center">
                <Link 
                  to="/login"
                  className="inline-flex items-center text-sm font-medium text-blue-600 hover:text-blue-700"
                >
                  <ArrowLeft className="mr-1 h-4 w-4" />
                  Zurück zum Login
                </Link>
              </div>
            </div>
          </div>

          <div className="mt-4 text-center text-sm text-gray-500">
            Bei Fragen kontaktieren Sie bitte unseren{' '}
            <a href="#" className="font-medium text-blue-600 hover:text-blue-700">
              Support
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
