import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Svg, Path } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 40,
    backgroundColor: '#ffffff',
    fontFamily: 'Helvetica'
  },
  header: {
    marginBottom: 30,
    position: 'relative',
  },
  headerPattern: {
    position: 'absolute',
    top: -40,
    left: -40,
    right: -40,
    height: 200,
    backgroundColor: '#000000',
  },
  headerContent: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingTop: 20,
  },
  headerLeft: {
    flex: 1,
  },
  logo: {
    width: 180,
    marginBottom: 15,
  },
  title: {
    fontSize: 32,
    marginBottom: 8,
    color: '#ffffff',
    fontFamily: 'Helvetica-Bold',
  },
  subtitle: {
    fontSize: 12,
    color: '#ffffff',
    letterSpacing: 1,
    opacity: 0.9,
  },
  statusContainer: {
    backgroundColor: '#ffffff',
    padding: 15,
    borderRadius: 8,
    width: 160,
  },
  statusBadge: {
    padding: 8,
    borderRadius: 4,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
  },
  section: {
    marginBottom: 25,
    position: 'relative',
  },
  sectionTitle: {
    fontSize: 16,
    marginBottom: 15,
    color: '#000000',
    fontFamily: 'Helvetica-Bold',
    borderBottom: 2,
    borderBottomColor: '#000000',
    paddingBottom: 8,
  },
  metricsGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 15,
    marginBottom: 20,
  },
  metricBox: {
    width: '31%',
    padding: 12,
    backgroundColor: '#f8f8f8',
    borderRadius: 8,
    position: 'relative',
  },
  metricTitle: {
    fontSize: 10,
    color: '#666666',
    marginBottom: 4,
    fontFamily: 'Helvetica-Bold',
  },
  metricValue: {
    fontSize: 14,
    color: '#000000',
    fontFamily: 'Helvetica-Bold',
  },
  metricSubtitle: {
    fontSize: 9,
    color: '#666666',
    marginTop: 4,
  },
  infoGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
  },
  infoBox: {
    width: '48%',
    padding: 12,
    backgroundColor: '#f8f8f8',
    borderRadius: 8,
    marginBottom: 10,
  },
  infoLabel: {
    fontSize: 10,
    color: '#666666',
    marginBottom: 4,
    fontFamily: 'Helvetica-Bold',
  },
  infoValue: {
    fontSize: 12,
    color: '#000000',
  },
  infoDetails: {
    marginTop: 6,
    paddingTop: 6,
    borderTopWidth: 1,
    borderTopColor: '#e0e0e0',
  },
  detailText: {
    fontSize: 9,
    color: '#666666',
    marginBottom: 2,
  },
  softwareTable: {
    width: '100%',
    marginTop: 10,
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#000000',
    padding: 8,
    marginBottom: 1,
  },
  tableHeaderText: {
    color: '#ffffff',
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    padding: 6,
  },
  tableCell: {
    fontSize: 9,
    color: '#333333',
  },
  col30: {
    width: '30%',
  },
  col25: {
    width: '25%',
  },
  col20: {
    width: '20%',
  },
  historySection: {
    marginTop: 15,
  },
  historyItem: {
    marginBottom: 10,
    padding: 10,
    backgroundColor: '#f8f8f8',
    borderRadius: 6,
  },
  historyTitle: {
    fontSize: 11,
    color: '#000000',
    fontFamily: 'Helvetica-Bold',
  },
  historyDetails: {
    fontSize: 9,
    color: '#666666',
    marginTop: 4,
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 40,
    right: 40,
    fontSize: 9,
    color: '#666666',
    textAlign: 'center',
    borderTopWidth: 1,
    borderTopColor: '#e0e0e0',
    paddingTop: 15,
  },
  usageBar: {
    height: 8,
    backgroundColor: '#e0e0e0',
    borderRadius: 4,
    marginTop: 6,
    overflow: 'hidden',
  },
  usageBarFill: {
    height: '100%',
    backgroundColor: '#000000',
  },
  pageNumber: {
    position: 'absolute',
    bottom: 30,
    right: 40,
    fontSize: 9,
    color: '#666666',
  }
});

const MetricBox = ({ title, value, subtitle }) => (
  <View style={styles.metricBox}>
    <Text style={styles.metricTitle}>{title}</Text>
    <Text style={styles.metricValue}>{value}</Text>
    {subtitle && <Text style={styles.metricSubtitle}>{subtitle}</Text>}
  </View>
);

const InfoBox = ({ label, value, details }) => (
  <View style={styles.infoBox}>
    <Text style={styles.infoLabel}>{label}</Text>
    <Text style={styles.infoValue}>{value}</Text>
    {details && (
      <View style={styles.infoDetails}>
        {details.map((detail, index) => (
          <Text key={index} style={styles.detailText}>{detail}</Text>
        ))}
      </View>
    )}
  </View>
);

export const AssetPDFDocument = ({ asset }) => {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('de-DE');
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerPattern} />
        <View style={styles.header}>
          <View style={styles.headerContent}>
            <View style={styles.headerLeft}>
              <Text style={styles.title}>{asset.Name}</Text>
              <Text style={styles.subtitle}>GERÄTE-DOKUMENTATION • {new Date().toLocaleString('de-DE')}</Text>
            </View>
            <View style={styles.statusContainer}>
              <View style={[styles.statusBadge, { 
                backgroundColor: asset.Status === 'OK' ? '#000000' : '#333333',
                color: '#ffffff'
              }]}>
                <Text>{asset.Status}</Text>
              </View>
              <Text style={{ fontSize: 10, color: '#666666', marginTop: 8, textAlign: 'center' }}>
                {asset.status.online ? 'ONLINE' : 'OFFLINE'}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>SYSTEM METRIKEN</Text>
          <View style={styles.metricsGrid}>
            <MetricBox
              title="PROZESSOR"
              value={asset.hardware.cpu.name}
              subtitle={`${asset.hardware.cpu.clockSpeed}MHz`}
            />
            <MetricBox
              title="ARBEITSSPEICHER"
              value={asset.hardware.memory.totalMemory}
              subtitle={`${asset.hardware.memory.slots.length} Module installiert`}
            />
            <MetricBox
              title="SPEICHERPLATZ"
              value={asset.hardware.storage.totalStorage}
              subtitle={`${asset.hardware.storage.devices.length} Laufwerke`}
            />
            <MetricBox
              title="BETRIEBSSYSTEM"
              value={asset.OperatingSystem}
              subtitle={asset.system.agentVersion}
            />
            <MetricBox
              title="BETRIEBSZEIT"
              value={`${asset.system.uptime} Stunden`}
              subtitle={asset.NeedsReboot ? 'Neustart erforderlich' : 'System aktuell'}
            />
            <MetricBox
              title="STANDORT"
              value={asset.business.location || 'Nicht angegeben'}
              subtitle={`Feature Level: ${asset.business.featureLevel}`}
            />
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>HARDWARE DETAILS</Text>
          <View style={styles.infoGrid}>
            <InfoBox
              label="MAINBOARD"
              value={asset.hardware.mainboard.manufacturer}
              details={[asset.hardware.mainboard.model]}
            />
            <InfoBox
              label="SYSTEM"
              value={asset.system.manufacturer}
              details={[
                `Modell: ${asset.system.model}`,
                `Seriennummer: ${asset.system.serialNumber}`
              ]}
            />
            <InfoBox
              label="SPEICHER KONFIGURATION"
              value={asset.hardware.storage.totalStorage}
              details={asset.hardware.storage.devices.map(device => {
                const parts = device.split('\n').map(s => s.trim()).filter(Boolean);
                return `${parts[3]} (${parts[5]})`;
              })}
            />
            <InfoBox
              label="RAM KONFIGURATION"
              value={asset.hardware.memory.totalMemory}
              details={asset.hardware.memory.slots.map(slot => {
                const parts = slot.split('\n').map(s => s.trim()).filter(Boolean);
                return `${parts[1]}: ${parts[3]} @ ${parts[5]}MHz`;
              })}
            />
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>NETZWERK & SICHERHEIT</Text>
          <View style={styles.infoGrid}>
            <InfoBox
              label="NETZWERK"
              value="IP-Konfiguration"
              details={asset.IPAddress.split(', ')}
            />
            <InfoBox
              label="MAC-ADRESSEN"
              value="Hardware-Adressen"
              details={asset.MacAddress.split(', ')}
            />
            <InfoBox
              label="ANTIVIRUS"
              value={asset.security.antiVirus.details}
              details={[`Status: ${asset.security.antiVirus.enabled}`]}
            />
            <InfoBox
              label="ANTI-SPYWARE"
              value={asset.security.antiSpyware.details}
              details={[`Status: ${asset.security.antiSpyware.enabled}`]}
            />
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>SYSTEM INFORMATIONEN</Text>
          <View style={styles.infoGrid}>
            <InfoBox
              label="HOSTNAME"
              value={asset.HostName}
              details={[asset.FQDN]}
            />
            <InfoBox
              label="GERÄTEDATEN"
              value="Systemdaten"
              details={[
                `Letzte Verbindung: ${formatDate(asset.dates.lastConnected)}`,
                `Gekauft: ${formatDate(asset.dates.purchased)}`,
                `Garantie bis: ${formatDate(asset.dates.warrantyExpiry)}`
              ]}
            />
          </View>
        </View>

        <Text style={styles.footer}>
          ARMANN SYSTEMS GMBH • ASSET MANAGEMENT • DOKUMENTATION ERSTELLT AM {formatDate(new Date())}
        </Text>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>

      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>INSTALLIERTE SOFTWARE</Text>
          <View style={styles.softwareTable}>
            <View style={styles.tableHeader}>
              <Text style={[styles.tableHeaderText, styles.col30]}>ANWENDUNG</Text>
              <Text style={[styles.tableHeaderText, styles.col25]}>VERSION</Text>
              <Text style={[styles.tableHeaderText, styles.col25]}>HERSTELLER</Text>
              <Text style={[styles.tableHeaderText, styles.col20]}>STATUS</Text>
            </View>
            {asset.software.slice(0, 20).map((app, index) => (
              <View key={index} style={[styles.tableRow, index % 2 === 0 && { backgroundColor: '#f8f8f8' }]}>
                <Text style={[styles.tableCell, styles.col30]}>{app.Application}</Text>
                <Text style={[styles.tableCell, styles.col25]}>{app.Version}</Text>
                <Text style={[styles.tableCell, styles.col25]}>{app.Publisher || 'N/A'}</Text>
                <Text style={[styles.tableCell, styles.col20]}>
                  {app.Visible === 'True' ? 'Aktiv' : 'Inaktiv'}
                </Text>
              </View>
            ))}
          </View>
          {asset.software.length > 20 && (
            <Text style={{ fontSize: 9, color: '#666666', marginTop: 10, textAlign: 'center' }}>
              Zeige 20 von {asset.software.length} installierten Anwendungen
            </Text>
          )}
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>AKTIONSVERLAUF</Text>
          <View style={styles.historySection}>
            {asset.actionHistory.slice(0, 10).map((action, index) => (
              <View key={index} style={styles.historyItem}>
                <Text style={styles.historyTitle}>{action.Description}</Text>
                <Text style={styles.historyDetails}>
                  {`${action.ActionType} • ${new Date(action.ActionedDate).toLocaleString('de-DE')} • ${action.AssignedUserName}`}
                </Text>
              </View>
            ))}
          </View>
          {asset.actionHistory.length > 10 && (
            <Text style={{ fontSize: 9, color: '#666666', marginTop: 10, textAlign: 'center' }}>
              Zeige die letzten 10 von {asset.actionHistory.length} Aktionen
            </Text>
          )}
        </View>

        <Text style={styles.footer}>
          ARMANN SYSTEMS GMBH • ASSET MANAGEMENT • DOKUMENTATION ERSTELLT AM {formatDate(new Date())}
        </Text>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  );
};

export default AssetPDFDocument;
