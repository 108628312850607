import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import QRCode from 'qrcode';

// Convert mm to points (1mm = 2.83465 points)
const mmToPt = (mm) => mm * 2.83465;

const styles = StyleSheet.create({
  page: {
    width: mmToPt(62),
    height: mmToPt(29),
    padding: mmToPt(3),
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    fontFamily: 'Helvetica'
  },
  content: {
    flex: 1,
    maxWidth: '60%',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  header: {
    marginBottom: mmToPt(2),
  },
  companyName: {
    fontSize: 8,
    fontFamily: 'Helvetica-Bold',
    textTransform: 'uppercase',
    color: '#000000',
    borderBottom: 1,
    borderBottomColor: '#000000',
    paddingBottom: mmToPt(1),
    marginBottom: mmToPt(2),
  },
  deviceInfo: {
    margin: `${mmToPt(2)}pt 0`,
  },
  label: {
    fontSize: 6,
    color: '#666666',
    marginBottom: mmToPt(0.5),
  },
  deviceName: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
    marginBottom: mmToPt(2),
  },
  serialNumber: {
    fontSize: 9,
    fontFamily: 'Courier',
    color: '#444444',
  },
  supportInfo: {
    fontSize: 6,
    color: '#666666',
    lineHeight: 1.3,
  },
  qrSection: {
    position: 'absolute',
    right: mmToPt(3),
    top: mmToPt(3),
    width: mmToPt(23),
    height: mmToPt(23),
    border: 1,
    borderColor: '#000000',
    padding: mmToPt(1),
  },
  qrCode: {
    width: '100%',
    height: '100%',
  }
});

export const AssetLabelPDFDocument = ({ asset, selectedFields = { name: true, serialNumber: true, support: true } }) => {
  // Generate QR code data URL synchronously
  let qrDataUrl = '';
  try {
    qrDataUrl = QRCode.toDataURL(
      `https://support.armann-systems.com/assets/${asset?.id || ''}`,
      { 
        errorCorrectionLevel: 'H',
        margin: 0,
        width: 256
      }
    );
  } catch (err) {
    console.error('Failed to generate QR code:', err);
  }

  return (
    <Document>
      <Page size={[mmToPt(62), mmToPt(29)]} style={styles.page}>
        <View style={styles.content}>
          <View>
            <View style={styles.header}>
              <Text style={styles.companyName}>Armann Systems</Text>
            </View>
            
            {(selectedFields.name || selectedFields.serialNumber) && (
              <View style={styles.deviceInfo}>
                {selectedFields.name && (
                  <>
                    <Text style={styles.label}>Gerätename:</Text>
                    <Text style={styles.deviceName}>{asset?.Name || 'N/A'}</Text>
                  </>
                )}
                {selectedFields.serialNumber && (
                  <>
                    <Text style={styles.label}>Seriennummer:</Text>
                    <Text style={styles.serialNumber}>{asset?.system?.serialNumber || 'N/A'}</Text>
                  </>
                )}
              </View>
            )}
          </View>
          
          {selectedFields.support && (
            <View style={styles.supportInfo}>
              <Text style={styles.label}>Support:</Text>
              <Text>+49 9401 91791 500</Text>
              <Text>support@armann-systems.com</Text>
              <Text>armann-systems.com</Text>
            </View>
          )}
        </View>
        
        <View style={styles.qrSection}>
          <Image
            style={styles.qrCode}
            src={qrDataUrl}
          />
        </View>
      </Page>
    </Document>
  );
};

export default AssetLabelPDFDocument;
