import React, { useState, useContext } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { 
  LayoutGrid, 
  Ticket, 
  FileText, 
  LogOut, 
  Bell,
  Search,
  LogIn,
  UserPlus,
  Menu,
  X, 
  Scan,
  BarChart,
  Settings
} from 'lucide-react';
import { NotificationContext } from '../contexts/NotificationContext';
import NotificationDropdown from './NotificationDropdown';
import NavbarScanner from './NavbarScanner';
import Logo from '../images/armann-systems-logo-single.png';

export const Navbar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const { newNotifications } = useContext(NotificationContext);
  const [showScanner, setShowScanner] = useState(false);
  
  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const isActivePath = (path) => {
    return location.pathname === path;
  };

  const navLinks = [
    { path: '/dashboard', label: 'Dashboard', icon: LayoutGrid },
    { path: '/tickets', label: 'Tickets', icon: Ticket },
    { path: '/assets', label: 'Assets', icon: FileText },
    { path: '/wiki', label: 'Dokumentation', icon: FileText },
    { path: '/insights', label: 'Statistiken', icon: BarChart },
  ];

  // Only show certain links for admin users
  const adminLinks = [
    { path: '/settings', label: 'Einstellungen', icon: Settings },
  ];

  const handleScannerClick = () => {
    setShowScanner(true);
  };

  return (
    <nav className="sticky top-0 z-40 bg-white border-b border-gray-200 shadow-sm">
      <div className="max-w-7xl mx-auto px-4 py-3">
        <div className="flex justify-between items-center">
          {/* Logo Section */}
          <div className="flex items-center gap-8">
            <Link 
              to="/" 
              className="flex items-center transition-transform hover:scale-105"
            >
              <img 
                src={Logo}
                alt="Armann Systems" 
                className="h-10 w-auto" 
              />
            </Link>
            
            {/* Desktop Navigation */}
            {user && (
              <div className="hidden md:flex items-center gap-1">
                {navLinks.map(({ path, label, icon: Icon }) => (
                  <Link 
                    key={path}
                    to={path} 
                    className={`px-4 py-2 rounded-lg text-sm font-medium flex items-center gap-2 transition-colors
                      ${isActivePath(path) 
                        ? 'text-blue-600 bg-blue-50' 
                        : 'text-gray-600 hover:text-blue-600 hover:bg-gray-50'
                      }`}
                  >
                    <Icon size={18} />
                    {label}
                  </Link>
                ))}
                {user.role === 'admin' && adminLinks.map(({ path, label, icon: Icon }) => (
                  <Link 
                    key={path}
                    to={path} 
                    className={`px-4 py-2 rounded-lg text-sm font-medium flex items-center gap-2 transition-colors
                      ${isActivePath(path) 
                        ? 'text-blue-600 bg-blue-50' 
                        : 'text-gray-600 hover:text-blue-600 hover:bg-gray-50'
                      }`}
                  >
                    <Icon size={18} />
                    {label}
                  </Link>
                ))}
              </div>
            )}
          </div>
          
          {/* Right Section */}
          <div className="flex items-center gap-4">
            
            {user ? (
              <>
                {/* Scanner - Mobile Only */}
                <button 
                  className="md:hidden p-2 text-gray-600 hover:text-blue-600 hover:bg-gray-50 rounded-lg transition-colors"
                  onClick={handleScannerClick}
                >
                  <Scan size={20} />
                </button>

                {/* Search Button */}
                <button 
                  className="p-2 text-gray-600 hover:text-blue-600 hover:bg-gray-50 rounded-lg transition-colors"
                  onClick={() => navigate('/search')}
                >
                  <Search size={20} />
                </button>

                {showScanner && (
                  <NavbarScanner onClose={() => setShowScanner(false)} />
                )}

                {/* Notifications */}
                <div className="relative">
                  <button 
                    className={`p-2 rounded-lg transition-colors ${
                      showNotifications 
                        ? 'text-blue-600 bg-blue-50' 
                        : 'text-gray-600 hover:text-blue-600 hover:bg-gray-50'
                    }`}
                    onClick={() => setShowNotifications(!showNotifications)}
                  >
                    <Bell size={20} />
                    {newNotifications.length > 0 && (
                      <span className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full" />
                    )}
                  </button>
                  {showNotifications && (
                    <NotificationDropdown 
                      onClose={() => setShowNotifications(false)}
                    />
                  )}
                </div>

                {/* User Profile */}
                <div className="flex items-center gap-3 ml-2 p-2 rounded-lg hover:bg-gray-50 transition-colors cursor-pointer">
                  <div className="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center">
                    <span className="text-sm font-medium text-blue-600">
                      {user.firstname?.[0]}{user.lastname?.[0]}
                    </span>
                  </div>
                  <div className="text-right hidden sm:block">
                    <div className="text-sm font-medium text-gray-900">
                      {user.firstname} {user.lastname}
                    </div>
                    <div className="text-xs text-gray-500">
                      {user.role === "admin" ? "Administrator" : "Benutzer"}
                    </div>
                  </div>
                  <button 
                    onClick={handleLogout}
                    className="p-2 text-gray-600 hover:text-red-600 rounded-lg transition-colors"
                    title="Abmelden"
                  >
                    <LogOut size={20} />
                  </button>
                </div>

                {/* Mobile Menu Button */}
                <button
                  className="md:hidden p-2 text-gray-600 hover:text-blue-600 hover:bg-gray-50 rounded-lg transition-colors"
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                >
                  {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
                </button>
              </>
            ) : (
              // Not logged in
              <div className="flex items-center gap-3">
                <Link
                  to="/login"
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:text-blue-600 hover:bg-gray-50 rounded-lg transition-colors"
                >
                  <LogIn className="mr-2 h-5 w-5" />
                  Anmelden
                </Link>
                <Link
                  to="/register"
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors"
                >
                  <UserPlus className="mr-2 h-5 w-5" />
                  Registrieren
                </Link>
              </div>
            )}
          </div>
        </div>

        {/* Mobile Navigation Menu */}
        {user && isMobileMenuOpen && (
          <div className="md:hidden mt-4 py-4 border-t border-gray-100">
            <div className="flex flex-col gap-2">
              {navLinks.map(({ path, label, icon: Icon }) => (
                <Link
                  key={path}
                  to={path}
                  className={`px-4 py-2 rounded-lg text-sm font-medium flex items-center gap-2 transition-colors
                    ${isActivePath(path)
                      ? 'text-blue-600 bg-blue-50'
                      : 'text-gray-600 hover:text-blue-600 hover:bg-gray-50'
                    }`}
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <Icon size={18} />
                  {label}
                </Link>
              ))}
              {user.role === 'admin' && adminLinks.map(({ path, label, icon: Icon }) => (
                <Link
                  key={path}
                  to={path}
                  className={`px-4 py-2 rounded-lg text-sm font-medium flex items-center gap-2 transition-colors
                    ${isActivePath(path)
                      ? 'text-blue-600 bg-blue-50'
                      : 'text-gray-600 hover:text-blue-600 hover:bg-gray-50'
                    }`}
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <Icon size={18} />
                  {label}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};