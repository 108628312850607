export const formatters = {
  timestamp: (timestamp) => {
    const date = new Date(timestamp);
    const today = new Date();
    const isToday = date.toDateString() === today.toDateString();
    
    const timeStr = date.toLocaleTimeString('de-DE', { 
      hour: '2-digit', 
      minute: '2-digit',
      second: '2-digit'
    });
    
    if (isToday) {
      return timeStr;
    }
    
    return `${date.toLocaleDateString('de-DE', { 
      day: '2-digit', 
      month: '2-digit'
    })} ${timeStr}`;
  },
  
  tooltipTimestamp: (timestamp) => {
    return new Date(timestamp).toLocaleString('de-DE', {
      weekday: 'short',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  },

  cpu: (value) => `${value.toFixed(1)}%`,
  
  ram: (value) => `${value.toFixed(1)} GB`,
  
  network: (value) => {
    if (value === null || value === undefined) return '0 Mbps';
    return `${value.toFixed(1)} Mbps`;
  }
};
