import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { apiClient, ApiError } from '../utils/apiClient';

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Initialer Load des Users mit Validierung
  useEffect(() => {
    const initAuth = async () => {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        try {
          const { user } = await apiClient.get('/auth/me');
          setUser(user); // Vollständiger User mit `naveriskClientId`
          localStorage.setItem('user', JSON.stringify(user)); // Aktualisiere den gespeicherten User
        } catch (error) {
          console.warn('Session validation failed:', error);
          localStorage.removeItem('user');
          localStorage.removeItem('token');
        }
      }
      setLoading(false);
    };
  
    initAuth();
  }, []);
  
  const login = async (email, password) => {
    try {
      const { user, token } = await apiClient.post('/auth/login', { email, password });
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      if (token) {
        localStorage.setItem('token', token);
      }
      toast.success('Erfolgreich angemeldet!');
      return user;
    } catch (error) {
      if (error instanceof ApiError) {
        switch (error.status) {
          case 401:
            toast.error('Falsche E-Mail oder Passwort');
            break;
          case 403:
            toast.error('Ihr Account wurde deaktiviert. Bitte kontaktieren Sie den Support.');
            break;
          case 429:
            toast.error('Zu viele Anfragen, bitte versuchen Sie es später erneut.');
            break;
          default:
            toast.error(error.message || 'Login fehlgeschlagen.');
        }
      } else {
        toast.error('Netzwerkfehler. Überprüfen Sie ihre Verbindung.');
      }
      throw error;
    }
  };

  const register = async (email) => {
    try {
      await apiClient.post('/auth/register', { email });
      toast.success('Registrierung erfolgreich. Bitte überprüfen Sie Ihre E-Mail, um Ihr Konto zu verifizieren.');
    } catch (error) {
      if (error instanceof ApiError) {
        switch (error.status) {
          case 409:
            toast.error('Email existiert bereits');
            break;
          case 400:
            if (error.message.includes('Naverisk')) {
              toast.error('Diese E-Mail ist keinem Kunden in Naverisk zugeordnet.');
            } else {
              toast.error('Falsche Eingaben. Bitte überprüfen Sie die Felder.');
            }
            break;
          default:
            toast.error(error.message || 'Registrierung fehlgeschlagen');
        }
      } else {
        toast.error('Netzwerkfehler. Überprüfen Sie ihre Verbindung.');
      }
      throw error;
    }
  };

  const logout = async () => {
    try {
      await apiClient.post('/auth/logout');
    } catch (error) {
      console.warn('Logout request failed:', error);
    } finally {
      // Cleanup auch wenn der Server-Request fehlschlägt
      setUser(null);
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      toast.info('Erfolgreich abgemeldet');
    }
  };

  // Validiere den User-Status periodisch oder nach bestimmten Ereignissen
  const validateSession = async () => {
    try {
      const { user: validatedUser } = await apiClient.get('/auth/me');
      setUser(validatedUser);
    } catch (error) {
      if (error.status === 401) {
        logout();
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Oder ein Loading-Spinner
  }

  return (
    <AuthContext.Provider value={{ 
      user, 
      login, 
      logout,
      register,
      validateSession,
      isAuthenticated: !!user 
    }}>
      {children}
    </AuthContext.Provider>
  );
};
