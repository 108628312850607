export const TIME_RANGES = [
  { label: 'Letzte Stunde', value: '1h', ms: 60 * 60 * 1000 },
  { label: '24 Stunden', value: '24h', ms: 24 * 60 * 60 * 1000 },
  { label: '7 Tage', value: '7d', ms: 7 * 24 * 60 * 60 * 1000 },
  { label: '30 Tage', value: '30d', ms: 30 * 24 * 60 * 60 * 1000 },
  { label: 'Benutzerdefiniert', value: 'custom' }
];

export const REFRESH_INTERVALS = [
  { label: 'Aus', value: 0 },
  { label: '1s', value: 1000 },
  { label: '5s', value: 5000 },
  { label: '10s', value: 10000 },
  { label: '60s', value: 60000 }
];

export const MEMORY_LABELS = {
  used_ram_gb: 'Verwendet',
  free_ram_gb: 'Verfügbar', 
  total_ram_gb: 'Gesamt',
  swap_used_gb: 'Swap verwendet'
};
