//debug
//const API_BASE_URL = 'http://192.168.250.114:3001/api';

//prod
const API_BASE_URL = 'https://support.armann-systems.com/api';

export class ApiError extends Error {
  constructor(message, status, data) {
    super(message);
    this.status = status;
    this.data = data;
    this.name = 'ApiError';
  }
}

export const apiClient = {
  async fetch(endpoint, options = {}) {
    const token = localStorage.getItem('token'); // Token aus dem LocalStorage holen
    const storedUser = localStorage.getItem('user');
    const naveriskClientId = storedUser ? JSON.parse(storedUser).naveriskClientId : null;

    const defaultOptions = {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...(token ? { Authorization: `Bearer ${token}` } : {}), // Token hinzufügen, falls vorhanden
        ...(naveriskClientId ? { 'X-Naverisk-Client-Id': naveriskClientId } : {}), // Add Naverisk Client ID if available
        ...options.headers,
      },
    };

    // Handle query parameters
    let finalEndpoint = endpoint;
    if (options.params) {
      const searchParams = new URLSearchParams();
      Object.entries(options.params).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
          searchParams.append(key, value);
        }
      });
      const queryString = searchParams.toString();
      if (queryString) {
        finalEndpoint = `${endpoint}${endpoint.includes('?') ? '&' : '?'}${queryString}`;
      }
    }

    try {
      console.log(`[ApiClient] Making request to: ${API_BASE_URL}${finalEndpoint}`);
      const response = await fetch(`${API_BASE_URL}${finalEndpoint}`, {
        ...defaultOptions,
        ...options,
        headers: {
          ...defaultOptions.headers,
          ...options.headers,
        },
      });

      if (response.status === 204) {
        return null;
      }

      const data = await response.json();

      if (!response.ok) {
        // Handle specific Naverisk API error cases
        if (response.status === 401) {
          // Token expired or invalid, trigger re-login
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          window.location.href = '/login';
          throw new ApiError('Session expired. Please login again.', 401, data);
        }
        throw new ApiError(
          data.message || 'API request failed',
          response.status,
          data
        );
      }

      return data;
    } catch (error) {
      if (error instanceof ApiError) {
        throw error;
      }
      if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
        throw new ApiError('Network error - Unable to connect to the server', 0);
      }
      throw new ApiError(error.message, 500);
    }
  },

  get(endpoint, params) {
    return this.fetch(endpoint, { params });
  },

  post(endpoint, data) {
    return this.fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  },

  put(endpoint, data) {
    return this.fetch(endpoint, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  },

  delete(endpoint) {
    return this.fetch(endpoint, {
      method: 'DELETE',
    });
  },
};
