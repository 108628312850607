import React from 'react';
import { Timer } from 'lucide-react';
import { REFRESH_INTERVALS } from '../constants';

export const RefreshSelector = ({ selectedInterval, onIntervalChange }) => (
  <div className="flex items-center gap-2">
    <Timer className="w-4 h-4 text-gray-500" />
    <select
      value={selectedInterval}
      onChange={(e) => onIntervalChange(Number(e.target.value))}
      className="border rounded px-2 pr-8 py-1.5 text-sm bg-white text-gray-500 text-left"
    >
      {REFRESH_INTERVALS.map((interval) => (
        <option key={interval.value} value={interval.value} className="text-left">
          {interval.label}
        </option>
      ))}
    </select>
  </div>
);

export default RefreshSelector;
