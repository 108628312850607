import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { apiClient } from '../utils/apiClient';
import { toast } from 'react-toastify';
import { LoaderContainer } from '../components/Loader';
import { Plus, Search, Filter, MoreVertical, AlertCircle, ChevronLeft, ChevronRight } from 'lucide-react';
import debounce from 'lodash/debounce';

const STATUS_BADGES = {
  'Unassigned': { color: 'bg-yellow-100 text-yellow-800 border-yellow-200', label: 'Nicht zugewiesen' },
  'Assigned': { color: 'bg-blue-100 text-blue-800 border-blue-200', label: 'Zugewiesen' },
  'Closed': { color: 'bg-gray-100 text-gray-800 border-gray-200', label: 'Geschlossen' },
  'Auto-Closed': { color: 'bg-gray-100 text-gray-800 border-gray-200', label: 'Auto-Geschlossen' },
  'Archived': { color: 'bg-purple-100 text-purple-800 border-purple-200', label: 'Archiviert' },
  'default': { color: 'bg-gray-100 text-gray-800 border-gray-200', label: 'Unbekannt' }
};

const formatDate = (dateString) => {
  try {
    return new Date(dateString).toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  } catch (e) {
    return 'Ungültiges Datum';
  }
};

const getStatusBadge = (status) => {
  return STATUS_BADGES[status] || STATUS_BADGES.default;
};

const truncateText = (text, maxLength = 50) => {
  if (!text) return 'Kein Betreff';
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

export const TicketsPage = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 50,
    total: 0,
    totalPages: 0
  });
  const { user } = useAuth();
  const navigate = useNavigate();

  const loadTickets = async (page = 1, search = searchTerm, status = selectedStatus) => {
    const loadingState = search !== searchTerm ? setSearchLoading : setLoading;
    try {
      loadingState(true);
      console.log(`Loading tickets page ${page}, search: ${search}, status: ${status}`);
      
      const response = await apiClient.get(`/customers/${user.naveriskClientId}/tickets`, {
        page,
        pageSize: pagination.pageSize,
        status: status || undefined,
        search: search || undefined
      });
      
      console.log('Received response:', response);
      setTickets(response.tickets?.tickets || []);
      setPagination(response.tickets?.pagination || {
        page: 1,
        pageSize: 50,
        total: 0,
        totalPages: 0
      });
      setCurrentPage(page);
      setError(null);
    } catch (err) {
      setError(err.message);
      toast.error('Fehler beim Laden der Tickets');
    } finally {
      loadingState(false);
    }
  };

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      loadTickets(1, searchValue, selectedStatus);
    }, 500),
    [selectedStatus]
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  // Handle status change
  const handleStatusChange = (e) => {
    const value = e.target.value;
    setSelectedStatus(value);
    loadTickets(1, searchTerm, value);
  };

  useEffect(() => {
    if (user?.naveriskClientId) {
      loadTickets(currentPage);
    }
  }, [user?.naveriskClientId]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      console.log(`Changing to page ${newPage}`);
      loadTickets(newPage, searchTerm, selectedStatus);
    }
  };

  const renderEmptyState = () => (
    <div className="flex flex-col items-center justify-center p-8 text-center text-gray-700">
      <AlertCircle size={48} className="text-gray-400 mb-4" />
      <h3 className="text-lg font-medium mb-2">Keine Tickets gefunden</h3>
      <p className="text-gray-500 max-w-md">
        {searchTerm || selectedStatus
          ? 'Keine Tickets entsprechen den ausgewählten Filterkriterien.' 
          : 'Es wurden noch keine Tickets erstellt.'}
      </p>
    </div>
  );

  if (loading) {
    return <LoaderContainer text="Tickets werden geladen..." />;
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 rounded-xl p-4 text-red-700">
          <div className="flex items-center gap-2 mb-2">
            <AlertCircle size={20} />
            <span className="font-medium">Fehler beim Laden der Tickets</span>
          </div>
          <p className="mb-4">{error}</p>
          <button
            onClick={() => loadTickets(currentPage)}
            className="px-4 py-2 bg-red-100 rounded-md hover:bg-red-200 transition-colors"
          >
            Erneut versuchen
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Support Tickets</h1>
              <p className="mt-1 text-sm text-gray-500">
                {pagination.total} {pagination.total === 1 ? 'Ticket' : 'Tickets'} gefunden
              </p>
            </div>
          </div>
          
          {/* Ticket Statistics */}
          <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {/* Total Tickets */}
            <div className="bg-white p-4 rounded-xl border border-gray-100">
              <h3 className="text-sm font-medium text-gray-500">Gesamt Tickets</h3>
              <p className="mt-2 text-3xl font-bold text-gray-900">{pagination.total}</p>
            </div>

            {/* Unassigned Tickets */}
            <div className="bg-white p-4 rounded-xl border border-gray-100">
              <h3 className="text-sm font-medium text-gray-500">Nicht zugewiesen</h3>
              <p className="mt-2 text-3xl font-bold text-blue-600">
                {tickets.filter(t => t.TicketStatus === 'Unassigned').length}
              </p>
            </div>

            {/* Assigned Tickets */}
            <div className="bg-white p-4 rounded-xl border border-gray-100">
              <h3 className="text-sm font-medium text-gray-500">In Bearbeitung</h3>
              <p className="mt-2 text-3xl font-bold text-yellow-600">
                {tickets.filter(t => t.TicketStatus === 'Assigned').length}
              </p>
            </div>

            {/* Closed Tickets */}
            <div className="bg-white p-4 rounded-xl border border-gray-100">
              <h3 className="text-sm font-medium text-gray-500">Geschlossen</h3>
              <p className="mt-2 text-3xl font-bold text-green-600">
                {tickets.filter(t => ['Closed', 'Auto-Closed', 'Archived'].includes(t.TicketStatus)).length}
              </p>
            </div>
          </div>

          {/* Filter Bar */}
          <div className="mt-4 space-y-4">
            <div className="flex flex-col sm:flex-row gap-4">
              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder="Tickets durchsuchen..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 text-gray-900 placeholder-gray-500 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                />
                <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
                {searchLoading && (
                  <div className="absolute right-3 top-2.5">
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"></div>
                  </div>
                )}
              </div>
              <button 
                className={`inline-flex items-center px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors ${showFilters ? 'bg-gray-50' : ''}`}
                onClick={() => setShowFilters(!showFilters)}
              >
                <Filter size={20} className="mr-2" />
                Filter {showFilters ? 'ausblenden' : 'anzeigen'}
              </button>
            </div>

            {/* Filter Options */}
            {showFilters && (
              <div className="flex flex-wrap gap-4 p-4 bg-white rounded-lg border border-gray-200">
                <div className="flex-1 min-w-[200px]">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Status
                  </label>
                  <select
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 focus:border-blue-500 focus:ring-blue-500"
                  >
                    <option value="">Alle Status</option>
                    {Object.entries(STATUS_BADGES)
                      .filter(([key]) => key !== 'default')
                      .map(([key, value]) => (
                        <option key={key} value={key}>{value.label}</option>
                      ))}
                  </select>
                </div>
                <button
                  onClick={() => {
                    setSelectedStatus('');
                    setSearchTerm('');
                    loadTickets(1, '', '');
                  }}
                  className="inline-flex items-center px-4 py-2 text-gray-700 bg-gray-50 border border-gray-200 rounded-lg hover:bg-gray-100"
                >
                  Filter zurücksetzen
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Tickets Table */}
        <div className="bg-white shadow-sm rounded-xl border border-gray-100 overflow-hidden">
          {tickets.length === 0 ? (
            renderEmptyState()
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 border-b border-gray-100">
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      Ticket #
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      Betreff
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      Erstellt
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider">
                      
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100">
                  {tickets.map((ticket) => (
                    <tr
                      key={ticket.TicketId}
                      onClick={() => navigate(`/tickets/${ticket.TicketId}`)}
                      className="hover:bg-gray-50 cursor-pointer transition-colors"
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        #{ticket.TicketId || '—'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {truncateText(ticket.Trigger)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2.5 py-1 text-xs font-medium rounded-full border ${getStatusBadge(ticket.TicketStatus).color}`}>
                          {getStatusBadge(ticket.TicketStatus).label}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {formatDate(ticket.CreatedDate)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right">
                        <button className="text-gray-400 hover:text-gray-900">
                          <MoreVertical size={16} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination */}
          {pagination.totalPages > 1 && (
            <div className="px-6 py-4 border-t border-gray-100 bg-gray-50">
              <div className="flex items-center justify-between">
                <div className="text-sm text-gray-700">
                  Seite {currentPage} von {pagination.totalPages}
                </div>
                <div className="flex gap-2">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="p-2 rounded-lg border border-gray-200 text-gray-600 hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <ChevronLeft size={16} />
                  </button>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === pagination.totalPages}
                    className="p-2 rounded-lg border border-gray-200 text-gray-600 hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <ChevronRight size={16} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
