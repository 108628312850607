import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { apiClient } from '../utils/apiClient';
import { LoaderWithText } from '../components/Loader';
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/AuthContext';
import { Ticket, FileText, ArrowUpRight, ArrowDownRight, Package, Users, Database, Book, List } from 'lucide-react';

const STATUS_COLORS = {
  'Unassigned': 'bg-yellow-100 text-yellow-800',
  'Assigned': 'bg-blue-100 text-blue-800',
  'Closed': 'bg-gray-100 text-gray-800',
  'Auto-Closed': 'bg-gray-100 text-gray-800',
  'Archived': 'bg-purple-100 text-purple-800',
};

export const DashboardPage = () => {
  const { user } = useAuth();
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statistics, setStatistics] = useState([]);

  const loadData = async () => {
    try {
      setLoading(true);
      console.log('[DashboardPage] Loading data for user:', user);

      // Get tickets from Naverisk API
      const response = await apiClient.get(`/customers/${user.naveriskClientId}/tickets`);
      console.log('[DashboardPage] Received tickets:', response);

      // Extract tickets from nested structure and take only the first 10
      const allTickets = response.tickets?.tickets || [];
      setTickets(allTickets);

      // Calculate statistics from tickets
      const stats = {
        activeTickets: {
          value: allTickets.filter(t => !['Closed', 'Auto-Closed', 'Archived'].includes(t.TicketStatus)).length,
          change: 0,
          isIncrease: true
        },
        unassignedTickets: {
          value: allTickets.filter(t => t.TicketStatus === 'Unassigned').length,
          change: 0,
          isIncrease: true
        },
        totalTickets: {
          value: allTickets.length,
          change: 0,
          isIncrease: true
        }
      };

      setStatistics([
        {
          title: "Aktive Tickets",
          value: stats.activeTickets.value,
          change: `${stats.activeTickets.change}%`,
          isIncrease: stats.activeTickets.isIncrease,
          icon: Ticket,
        },
        {
          title: "Offene Anfragen",
          value: stats.unassignedTickets.value,
          change: `${stats.unassignedTickets.change}%`,
          isIncrease: stats.unassignedTickets.isIncrease,
          icon: Package,
        },
        {
          title: "Gesamt Tickets",
          value: stats.totalTickets.value,
          change: `${stats.totalTickets.change}%`,
          isIncrease: stats.totalTickets.isIncrease,
          icon: Users,
        },
      ]);

      setError(null);
    } catch (err) {
      console.error('[DashboardPage] Error loading data:', err);
      setError(err.message);
      toast.error('Failed to load dashboard data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.naveriskClientId) {
      loadData();
    }
  }, [user?.naveriskClientId]);

  // Format the date to a more readable format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>Dashboard - Armann Systems GmbH</title>
      </Helmet>
      <main className="max-w-7xl mx-auto px-4 py-8">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900">
            Willkommen zurück, {user?.firstname}
          </h1>
          <p className="mt-1 text-gray-500">
            Hier ist eine Übersicht Ihrer Support-Aktivitäten
          </p>
        </div>

        {/* Statistik-Karten */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {statistics.map((stat, index) => (
            <div key={index} className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
              <div className="flex items-center justify-between mb-4">
                <span className="text-gray-500 font-medium">{stat.title}</span>
                <stat.icon className="text-blue-600" size={20} />
              </div>
              <div className="flex items-center justify-between">
                <span className="text-2xl font-bold text-gray-900">{stat.value}</span>
                <div className={`flex items-center ${
                  stat.isIncrease ? 'text-green-600' : 'text-red-600'
                }`}>
                  {stat.isIncrease ? (
                    <ArrowUpRight size={20} />
                  ) : (
                    <ArrowDownRight size={20} />
                  )}
                  <span className="ml-1 text-sm font-medium">{stat.change}</span>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Letzte Aktivitäten */}
          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-lg font-semibold text-gray-900">Letzte Aktivitäten</h2>
              <Link to="/tickets" className="text-blue-600 hover:text-blue-700 text-sm font-medium">
                Alle anzeigen
              </Link>
            </div>
            {loading ? (
              <div className="flex justify-center py-4">
                <LoaderWithText text="Tickets werden geladen..." />
              </div>
            ) : error ? (
              <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-700">
                Error loading tickets: {error}
                <button
                  onClick={loadData}
                  className="ml-4 px-3 py-1 bg-red-100 rounded-md hover:bg-red-200 transition-colors"
                >
                  Retry
                </button>
              </div>
            ) : tickets.length === 0 ? (
              <div className="text-center text-gray-500 py-4">
                Keine Tickets gefunden
              </div>
            ) : (
              <div className="space-y-4">
                {tickets.slice(0, 7).map((ticket) => (
                  <Link
                    key={ticket.TicketId}
                    to={`/tickets/${ticket.TicketId}`}
                    className="flex items-center justify-between py-3 border-b border-gray-100 last:border-0 hover:bg-gray-50 rounded-lg px-3 transition-colors"
                  >
                    <div className="flex items-center gap-4">
                      <div className="bg-blue-100 p-2 rounded-lg">
                        <Ticket className="text-blue-600" size={20} />
                      </div>
                      <div>
                        <div className="text-sm font-medium text-gray-900">
                          {ticket.Trigger}
                        </div>
                        <div className="text-sm text-gray-500">
                          {formatDate(ticket.CreatedDate)}
                        </div>
                      </div>
                    </div>
                    <span className={`px-3 py-1 text-xs font-medium rounded-full ${
                      STATUS_COLORS[ticket.TicketStatus] || 'bg-gray-100 text-gray-800'
                    }`}>
                      {ticket.TicketStatus}
                    </span>
                  </Link>
                ))}
              </div>
            )}
          </div>

          {/* Schnellzugriff */}
          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
            <h2 className="text-lg font-semibold text-gray-900 mb-6">Schnellzugriff</h2>
            <div className="grid grid-cols-2 gap-4">
              <Link 
                to="/tickets/new"
                className="flex items-center gap-3 p-4 rounded-lg border border-gray-200 hover:border-blue-600 hover:bg-blue-50 transition-all group"
              >
                <Ticket className="text-gray-400 group-hover:text-blue-600" size={24} />
                <div>
                  <div className="font-medium text-gray-900">Neues Ticket</div>
                  <div className="text-sm text-gray-500">Support-Anfrage erstellen</div>
                </div>
              </Link>
              <Link 
                to="/tickets"
                className="flex items-center gap-3 p-4 rounded-lg border border-gray-200 hover:border-blue-600 hover:bg-blue-50 transition-all group"
              >
                <List className="text-gray-400 group-hover:text-blue-600" size={24} />
                <div>
                  <div className="font-medium text-gray-900">Tickets</div>
                  <div className="text-sm text-gray-500">Alle Tickets anzeigen</div>
                </div>
              </Link>
              <Link 
                to="/wiki"
                className="flex items-center gap-3 p-4 rounded-lg border border-gray-200 hover:border-blue-600 hover:bg-blue-50 transition-all group"
              >
                <Book className="text-gray-400 group-hover:text-blue-600" size={24} />
                <div>
                  <div className="font-medium text-gray-900">Wiki</div>
                  <div className="text-sm text-gray-500">Wissensdatenbank</div>
                </div>
              </Link>
              <Link 
                to="/assets"
                className="flex items-center gap-3 p-4 rounded-lg border border-gray-200 hover:border-blue-600 hover:bg-blue-50 transition-all group"
              >
                <Database className="text-gray-400 group-hover:text-blue-600" size={24} />
                <div>
                  <div className="font-medium text-gray-900">Assets</div>
                  <div className="text-sm text-gray-500">Inventar verwalten</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default DashboardPage;
