import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { apiClient } from '../utils/apiClient';
import { useAuth } from '../contexts/AuthContext';
import { LoaderWithText } from '../components/Loader';
import { toast } from 'react-toastify';

export const WikiPage = () => {
  const { user } = useAuth();
  const [wikiPages, setWikiPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadWikiPages = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/${user.naveriskClientId}/wiki`);
      setWikiPages(response.wiki_pages);
      setError(null);
    } catch (err) {
      setError(err.message);
      toast.error('Failed to load wiki pages');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.naveriskClientId) {
      loadWikiPages();
    }
  }, [user?.naveriskClientId]);

  if (loading) {
    return <LoaderWithText text="Wiki-Seiten werden geladen..." />;
  }

  if (error) {
    return (
      <div className="text-red-600">
        Error: {error}
        <button onClick={loadWikiPages}>Retry</button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Wiki</h1>
      <div className="grid gap-4">
        {wikiPages.map((page) => (
          <Link
            key={page.id}
            to={`/wiki/${page.id}`}
            className="block p-4 bg-white rounded-lg shadow hover:shadow-md transition-shadow"
          >
            <h2 className="text-lg font-semibold">{page.title}</h2>
            <p className="text-gray-600 mt-2">{page.excerpt}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default WikiPage;
