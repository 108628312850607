import React, { useState, useEffect } from 'react';
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, 
  ResponsiveContainer, Legend, ReferenceArea
} from 'recharts';
import { formatters } from '../formatters';
import CustomTooltip from './CustomTooltip';
import CustomLegend from './CustomLegend';
import EmptyState from './EmptyState';

export const MetricChart = React.memo(({ 
  data, 
  title, 
  dataKey, 
  valueFormatter, 
  yAxisLabel, 
  metricName,
  showMultipleLines = false,
  dataLabels = {} 
}) => {
  const hasData = data && data.length > 0;
  const [activeItems, setActiveItems] = useState([]);
  const [left, setLeft] = useState(null);
  const [right, setRight] = useState(null);
  const [refAreaLeft, setRefAreaLeft] = useState('');
  const [refAreaRight, setRefAreaRight] = useState('');
  const [isZoomed, setIsZoomed] = useState(false);

  // Convert timestamps to numbers for better zooming
  const chartData = data.map(item => ({
    ...item,
    timeValue: new Date(item.timestamp).getTime()
  }));

  useEffect(() => {
    if (hasData && showMultipleLines) {
      const initialActive = Object.keys(data[0]).filter(key => 
        key !== 'timestamp' && 
        !key.includes('error') && 
        !key.includes('drop')
      );
      setActiveItems(initialActive);
    }
  }, [data, showMultipleLines]);

  const handleLegendClick = (item) => {
    setActiveItems(prev => {
      if (prev.includes(item)) {
        return prev.filter(i => i !== item);
      }
      return [...prev, item];
    });
  };

  const getLines = () => {
    if (!showMultipleLines) {
      return [
        <Line 
          key={dataKey}
          type="monotone" 
          dataKey={dataKey}
          name={dataLabels[dataKey] || metricName}
          stroke="#1a1a1a" 
          dot={false}
          strokeWidth={2}
          isAnimationActive={false}
          connectNulls
        />
      ];
    }

    const keys = Object.keys(data[0]).filter(key => 
      key !== 'timestamp' && 
      key !== 'timeValue' &&
      !key.includes('error') && 
      !key.includes('drop')
    );

    return keys.map((key, index) => (
      <Line
        key={key}
        type="monotone"
        dataKey={key}
        name={dataLabels[key] || key}
        stroke={`hsl(${index * (360 / keys.length)}, 70%, 50%)`}
        dot={false}
        strokeWidth={2}
        hide={!activeItems.includes(key)}
        isAnimationActive={false}
        connectNulls
      />
    ));
  };

  const handleMouseDown = (e) => {
    if (!e || !e.activeLabel) return;
    setRefAreaLeft(e.activeLabel);
  };

  const handleMouseMove = (e) => {
    if (!e || !e.activeLabel || !refAreaLeft) return;
    setRefAreaRight(e.activeLabel);
  };

  const handleMouseUp = () => {
    if (!refAreaLeft || !refAreaRight) {
      setRefAreaLeft('');
      setRefAreaRight('');
      return;
    }

    if (refAreaLeft === refAreaRight) {
      setRefAreaLeft('');
      setRefAreaRight('');
      return;
    }

    // Convert selected timestamps to numbers
    const leftTime = new Date(refAreaLeft).getTime();
    const rightTime = new Date(refAreaRight).getTime();

    // Set zoom boundaries
    setLeft(Math.min(leftTime, rightTime));
    setRight(Math.max(leftTime, rightTime));
    setIsZoomed(true);

    setRefAreaLeft('');
    setRefAreaRight('');
  };

  const handleZoomOut = () => {
    setLeft(null);
    setRight(null);
    setIsZoomed(false);
  };

  // Calculate domain based on zoom state
  const getDomain = () => {
    if (isZoomed && left !== null && right !== null) {
      return [left, right];
    }
    if (chartData.length > 0) {
      return ['dataMin', 'dataMax'];
    }
    return undefined;
  };

  return (
    <div className="bg-white p-6 rounded-lg border shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
        {isZoomed && (
          <button
            onClick={handleZoomOut}
            className="px-3 py-1 text-sm bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
          >
            Zoom zurücksetzen
          </button>
        )}
      </div>
      <div 
        className="h-[300px]" 
        style={{ userSelect: 'none' }}
      >
        {!hasData ? <EmptyState /> : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
              <XAxis 
                dataKey="timeValue"
                type="number"
                scale="time"
                domain={getDomain()}
                tickFormatter={(value) => formatters.timestamp(new Date(value))}
                allowDataOverflow
                stroke="#666"
                tick={{ fontSize: 12 }}
                tickLine={{ stroke: '#666' }}
              />
              <YAxis 
                label={{ 
                  value: yAxisLabel, 
                  angle: -90, 
                  position: 'insideLeft',
                  style: { textAnchor: 'middle' }
                }}
                stroke="#666"
                tick={{ fontSize: 12 }}
                tickLine={{ stroke: '#666' }}
              />
              <Tooltip 
                content={<CustomTooltip 
                  valueFormatter={valueFormatter} 
                  metricName={metricName}
                  dataLabels={dataLabels}
                />}
                cursor={{ stroke: '#666', strokeWidth: 1 }}
              />
              {getLines()}
              {showMultipleLines && (
                <Legend 
                  content={<CustomLegend 
                    activeItems={activeItems}
                    onItemClick={handleLegendClick}
                  />} 
                />
              )}
              {refAreaLeft && refAreaRight && (
                <ReferenceArea
                  x1={new Date(refAreaLeft).getTime()}
                  x2={new Date(refAreaRight).getTime()}
                  strokeOpacity={0.3}
                  fill="#8884d8"
                  fillOpacity={0.3}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
});

export default MetricChart;
