import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { apiClient } from '../utils/apiClient';
import { toast } from 'react-toastify';
import { LoaderContainer } from '../components/Loader';
import { 
  Search, Filter, MoreVertical, Server, HardDrive, LucideMemoryStick,
  Monitor, Shield, AlertTriangle, CheckCircle, Cpu, Globe,
  Calendar, Clock, AlertOctagon, Building, Wifi, WifiOff, ShieldAlert
} from 'lucide-react';

const STATUS_BADGES = {
  'OK': 'bg-green-100 text-green-800 border-green-200',
  'Threat': 'bg-red-100 text-red-800 border-red-200',
  'Not configured': 'bg-yellow-100 text-yellow-800 border-yellow-200',
  'Failure': 'bg-red-100 text-red-800 border-red-200'
};

const WARRANTY_BADGES = {
  'Unknown': 'bg-gray-100 text-gray-800 border-gray-200',
  'Expired': 'bg-red-100 text-red-800 border-red-200',
  'Valid': 'bg-green-100 text-green-800 border-green-200',
};

const StatusBadge = ({ status }) => {
  const icon = status === 'OK' ? CheckCircle : status === 'Threat' ? ShieldAlert : AlertTriangle;
  const Icon = icon;
  const badgeClass = STATUS_BADGES[status] || 'bg-gray-100 text-gray-800 border-gray-200';
  return (
    <span className={`px-2.5 py-1 text-xs font-medium rounded-full border inline-flex items-center gap-1 ${badgeClass}`}>
      <Icon size={12} />
      {status}
    </span>
  );
};

const WarrantyBadge = ({ status }) => {
  const badgeClass = WARRANTY_BADGES[status] || WARRANTY_BADGES.Unknown;
  return (
    <span className={`px-2.5 py-1 text-xs font-medium rounded-full border ${badgeClass}`}>
      {status}
    </span>
  );
};

const MetricCard = ({ title, value, icon: Icon, className }) => (
  <div className={`bg-white p-4 rounded-lg border shadow-sm ${className}`}>
    <div className="flex items-center justify-between">
      <div>
        <p className="text-sm text-gray-700">{title}</p>
        <p className="mt-1 text-xl font-semibold text-gray-900">{value}</p>
      </div>
      <Icon className="h-8 w-8 text-gray-400" />
    </div>
  </div>
);

const AssetCard = ({ asset, onClick }) => {
  const status = asset.Status || 'Not configured';
  const operatingSystem = asset.OperatingSystem || 'N/A';
  const ipAddress = asset.IPAddress || 'N/A';
  const lastSeen = asset.LastConnectedDate ? new Date(asset.LastConnectedDate) : null;
  const formattedLastSeen = lastSeen ? lastSeen.toLocaleString('de-DE') : 'Nie';

  return (
    <div 
      onClick={onClick}
      className="bg-white rounded-lg border hover:shadow-md transition-all cursor-pointer p-4"
    >
      <div className="flex items-start justify-between mb-4">
        <div className="flex items-center gap-3">
          <Server className="w-8 h-8 text-gray-900" />
          <div>
            <h3 className="font-medium text-gray-900">{asset.Name}</h3>
            <p className="text-sm text-gray-700">{asset.DeviceTypeName || 'Unbekannter Typ'}</p>
          </div>
        </div>
        <div className="flex flex-col gap-2 items-end">
          <StatusBadge status={status} />
          {asset.online ? 
            <span className="text-green-600 flex items-center gap-1 text-sm">
              <Wifi size={14} /> Online
            </span> :
            <span className="text-gray-500 flex items-center gap-1 text-sm">
              <WifiOff size={14} /> Offline
            </span>
          }
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <p className="text-sm text-gray-500 mb-1">System</p>
          <p className="text-sm text-gray-700">{operatingSystem}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500 mb-1">IP</p>
          <p className="text-sm text-gray-700">{ipAddress}</p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <p className="text-sm text-gray-500 mb-1">Standort</p>
          <p className="text-sm text-gray-700">{asset.Location || 'Nicht angegeben'}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500 mb-1">Garantie</p>
          <WarrantyBadge status={asset.warrantyStatus} />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="text-sm text-gray-500 mb-1">Antivirus</p>
          <p className="text-sm text-gray-700">{asset.AntiVirusEnabled || 'N/A'}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500 mb-1">Zuletzt gesehen</p>
          <p className="text-sm text-gray-700">{formattedLastSeen}</p>
        </div>
      </div>
    </div>
  );
};

export const AssetsPage = () => {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedDeviceType, setSelectedDeviceType] = useState('');
  const [selectedOnlineStatus, setSelectedOnlineStatus] = useState('');
  const [selectedWarrantyStatus, setSelectedWarrantyStatus] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const { user } = useAuth();
  const navigate = useNavigate();

  const loadAssets = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/customers/${user.naveriskClientId}/devices`);
      if (response.devices && response.devices.devices) {
        setAssets(response.devices.devices);
      } else {
        setAssets([]);
      }
      setError(null);
    } catch (err) {
      setError(err.message);
      toast.error('Fehler beim Laden der Assets');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.naveriskClientId) {
      loadAssets();
    }
  }, [user?.naveriskClientId]);

  const getMetrics = () => {
    const onlineCount = assets.filter(a => a.online).length;
    const threatCount = assets.filter(a => a.Status === 'Threat').length;
    const warrantyExpiredCount = assets.filter(a => a.warrantyStatus === 'Expired').length;
    const antiVirusIssues = assets.filter(a => a.AntiVirusEnabled !== 'OK').length;

    return [
      { title: 'Online Geräte', value: `${onlineCount}/${assets.length}`, icon: Wifi },
      { title: 'Bedrohungen', value: threatCount, icon: ShieldAlert },
      { title: 'Garantie abgelaufen', value: warrantyExpiredCount, icon: AlertOctagon },
      { title: 'AV Probleme', value: antiVirusIssues, icon: Shield }
    ];
  };

  const getUniqueDeviceTypes = () => {
    return [...new Set(assets.map(asset => asset.DeviceTypeName).filter(Boolean))];
  };

  const filteredAssets = assets
    .filter(asset => {
      const matchesSearch = searchTerm === '' || 
        asset.Name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        asset.Model?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        asset.OperatingSystem?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        asset.Location?.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesStatus = selectedStatus === '' || asset.Status === selectedStatus;
      const matchesDeviceType = selectedDeviceType === '' || asset.DeviceTypeName === selectedDeviceType;
      const matchesOnline = selectedOnlineStatus === '' || 
        (selectedOnlineStatus === 'online' ? asset.online : !asset.online);
      const matchesWarranty = selectedWarrantyStatus === '' || asset.warrantyStatus === selectedWarrantyStatus;
      
      return matchesSearch && matchesStatus && matchesDeviceType && matchesOnline && matchesWarranty;
    });

  if (loading) {
    return <LoaderContainer text="Assets werden geladen..." />;
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 rounded-xl p-4 text-red-700">
          <div className="flex items-center gap-2 mb-2">
            <AlertTriangle size={20} />
            <span className="font-medium">Fehler beim Laden der Assets</span>
          </div>
          <p className="mb-4">{error}</p>
          <button
            onClick={loadAssets}
            className="px-4 py-2 bg-red-100 rounded-md hover:bg-red-200 transition-colors"
          >
            Erneut versuchen
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        {/* Header & Metrics */}
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-6">Assets</h1>
          
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
            {getMetrics().map((metric, index) => (
              <MetricCard key={index} {...metric} />
            ))}
          </div>
          
          {/* Filter Bar */}
          <div className="space-y-4">
            <div className="flex flex-col sm:flex-row gap-4">
              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder="Assets durchsuchen..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-gray-900 focus:border-gray-900"
                />
                <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
              </div>
              <button 
                className="inline-flex items-center px-4 py-2 text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-50"
                onClick={() => setShowFilters(!showFilters)}
              >
                <Filter size={20} className="mr-2" />
                Filter
              </button>
              <div className="flex gap-2">
                <button
                  onClick={() => setViewMode('grid')}
                  className={`p-2 rounded-lg border ${viewMode === 'grid' ? 'bg-gray-900 text-white' : 'bg-white border-gray-200'}`}
                >
                  <Monitor size={20} />
                </button>
                <button
                  onClick={() => setViewMode('table')}
                  className={`p-2 rounded-lg border ${viewMode === 'table' ? 'bg-gray-900 text-white' : 'bg-white border-gray-200'}`}
                >
                  <Server size={20} />
                </button>
              </div>
            </div>

            {showFilters && (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-4 bg-white rounded-lg border border-gray-200">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Status
                  </label>
                  <select
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-700"
                  >
                    <option value="">Alle Status</option>
                    {Object.keys(STATUS_BADGES).map(status => (
                      <option key={status} value={status}>{status}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Gerätetyp
                  </label>
                  <select
                    value={selectedDeviceType}
                    onChange={(e) => setSelectedDeviceType(e.target.value)}
                    className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-700"
                  >
                    <option value="">Alle Typen</option>
                    {getUniqueDeviceTypes().map(type => (
                      <option key={type} value={type}>{type}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Online Status
                  </label>
                  <select
                    value={selectedOnlineStatus}
                    onChange={(e) => setSelectedOnlineStatus(e.target.value)}
                    className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-700"
                  >
                    <option value="">Alle</option>
                    <option value="online">Online</option>
                    <option value="offline">Offline</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Garantie Status
                  </label>
                  <select
                    value={selectedWarrantyStatus}
                    onChange={(e) => setSelectedWarrantyStatus(e.target.value)}
                    className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-700"
                  >
                    <option value="">Alle</option>
                    <option value="Valid">Gültig</option>
                    <option value="Expired">Abgelaufen</option>
                    <option value="Unknown">Unbekannt</option>
                  </select>
                </div>
                <div className="sm:col-span-2 lg:col-span-4">
                  <button
                    onClick={() => {
                      setSelectedStatus('');
                      setSelectedDeviceType('');
                      setSelectedOnlineStatus('');
                      setSelectedWarrantyStatus('');
                      setSearchTerm('');
                    }}
                    className="inline-flex items-center px-4 py-2 text-gray-700 bg-gray-50 border border-gray-200 rounded-lg hover:bg-gray-100"
                  >
                    Filter zurücksetzen
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Assets Content */}
        {filteredAssets.length === 0 ? (
          <div className="bg-white rounded-xl border p-8 text-center text-gray-700">
            <AlertTriangle size={48} className="mx-auto mb-4 text-gray-400" />
            <h3 className="text-lg font-medium mb-2">Keine Assets gefunden</h3>
            <p className="text-gray-500">
              {searchTerm || selectedStatus || selectedDeviceType || selectedOnlineStatus || selectedWarrantyStatus
                ? 'Keine Assets entsprechen den ausgewählten Filterkriterien.'
                : 'Es wurden noch keine Assets gefunden.'}
            </p>
          </div>
        ) : viewMode === 'grid' ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredAssets.map((asset) => (
              <AssetCard
                key={asset.DeviceId}
                asset={asset}
                onClick={() => navigate(`/assets/${asset.DeviceId}`)}
              />
            ))}
          </div>
        ) : (
          <div className="bg-white shadow overflow-hidden rounded-xl border">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-900 text-white">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                    System
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                    Typ
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                    Standort
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                    Zuletzt gesehen
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredAssets.map((asset) => (
                  <tr
                    key={asset.DeviceId}
                    onClick={() => navigate(`/assets/${asset.DeviceId}`)}
                    className="hover:bg-gray-50 cursor-pointer"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          {asset.online ? 
                            <Wifi className="h-5 w-5 text-green-500"/> :
                            <WifiOff className="h-5 w-5 text-gray-400"/>
                          }
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{asset.Name}</div>
                          <div className="text-sm text-gray-500">{asset.Model || 'Kein Modell'}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <StatusBadge status={asset.Status || 'Not configured'} />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{asset.OperatingSystem || 'N/A'}</div>
                      <div className="text-xs text-gray-500">{asset.DeviceClassName || 'N/A'}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-700">{asset.DeviceTypeName || 'N/A'}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-700">{asset.Location || 'N/A'}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-700">
                        {asset.LastConnectedDate ? new Date(asset.LastConnectedDate).toLocaleString('de-DE') : 'Nie'}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetsPage;
