import React from 'react';

export const Loader = ({ className = '', size = 'default' }) => {
  const sizeClasses = {
    small: 'w-4 h-4',
    default: 'w-8 h-8',
    large: 'w-12 h-12',
  };

  return (
    <div className={`flex items-center justify-center ${className}`}>
      <div 
        className={`relative ${sizeClasses[size] || sizeClasses.default}`}
      >
        <div className="absolute inset-0 rounded-full border-2 border-gray-100" />
        <div 
          className="absolute inset-0 rounded-full border-2 border-blue-600 border-l-transparent animate-[spin_0.6s_linear_infinite]"
          style={{ animationTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)' }}
        />
      </div>
    </div>
  );
};

export const LoaderWithText = ({ 
  text = 'Laden...', 
  className = '', 
  size = 'default' 
}) => {
  return (
    <div className={`flex flex-col items-center gap-2 ${className}`}>
      <Loader size={size} />
      <span className="text-sm text-gray-400 font-medium">{text}</span>
    </div>
  );
};

export const LoaderContainer = ({ 
  fullHeight = false,
  text,
  size = 'default'
}) => {
  return (
    <div className={`flex items-center justify-center bg-gray-50 ${
      fullHeight ? 'min-h-[calc(100vh-4rem)]' : 'p-8'
    }`}>
      {text ? (
        <LoaderWithText text={text} size={size} />
      ) : (
        <Loader size={size} />
      )}
    </div>
  );
};