import React, { useState, useEffect } from 'react';
import { apiClient } from '../utils/apiClient';
import { AlertOctagon, Calendar, Monitor, CheckCircle, XCircle } from 'lucide-react';
import { Link } from 'react-router-dom';

const NotificationsPage = () => {
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const notificationsPerPage = 5;

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await apiClient.get('/alerts/rmm_alerts');
        setNotifications(response.rmm_alerts || []);
      } catch (error) {
        console.error('Fehler beim Abrufen der Warnungen:', error);
      }
    };

    fetchAlerts();
  }, []);

  const indexOfLastNotification = currentPage * notificationsPerPage;
  const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
  const currentNotifications = notifications.slice(indexOfFirstNotification, indexOfLastNotification);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">Benachrichtigungen</h1>
        <ul className="space-y-4">
          {currentNotifications.map((alert, index) => (
            <li key={index} className="bg-white p-4 rounded-lg border shadow-sm">
              <div className="flex items-center gap-2">
                <AlertOctagon className="w-5 h-5 text-red-500" />
                <div>
                  <p className="text-sm font-medium text-gray-900">
                    <Link to={`/assets/${alert.asset_id}`} className="text-blue-500 hover:underline">
                      {alert.computer_name || 'Unbekannter Computer'}
                    </Link>
                  </p>
                  <p className="text-xs text-gray-500 flex items-center gap-1">
                    <Monitor className="w-4 h-4" />
                    {alert.computer_name || 'Unbekannter Computer'}
                  </p>
                  <p className="text-xs text-gray-500 flex items-center gap-1">
                    <Calendar className="w-4 h-4" />
                    {new Date(alert.created_at).toLocaleString() || 'Unbekanntes Datum'}
                  </p>
                  <div className="text-xs text-gray-500 mt-2">
                    {alert.asset_id && (
                      <Link to={`/assets/${alert.asset_id}`} className="text-blue-500 hover:underline mr-2">
                        [Asset]
                      </Link>
                    )}
                    {alert.ticket_number && (
                      <Link to={`/tickets/${alert.ticket_number}`} className="text-blue-500 hover:underline">
                        [Ticket]
                      </Link>
                    )}
                  </div>
                  <pre className="text-xs text-gray-700 mt-2 whitespace-pre-wrap">
                    {alert.formatted_output || 'Keine Details verfügbar'}
                  </pre>
                  <div className="flex items-center gap-1 mt-2">
                    {alert.resolved ? (
                      <CheckCircle className="w-4 h-4 text-green-500" />
                    ) : (
                      <XCircle className="w-4 h-4 text-red-500" />
                    )}
                    <span className="text-xs text-gray-500">
                      {alert.resolved ? 'Resolved' : 'Unresolved'}
                    </span>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="flex justify-center mt-4">
          {Array.from({ length: Math.ceil(notifications.length / notificationsPerPage) }, (_, i) => (
            <button
              key={i}
              onClick={() => paginate(i + 1)}
              className={`px-3 py-1 mx-1 rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-white text-blue-500 border'}`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotificationsPage;
